import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Header from '../../../Components/Header';
import * as UserAction from '../../../Store/Actions/User';
import './Home.scss';
import FooterManager from '../../../Components/FooterManager';
import { useGameReducer } from '../../../Store';
import { roundBalance } from '../../../Utils';

const ManagerDashboard = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [amount, setAmount] = useState(0);

  const gameReducer = useGameReducer();

  const redirect = (url) => {
    navigate(url);
  };

  useEffect(() => {
    if (gameReducer.userDetail) {
      setName(gameReducer.userDetail.name);
    }
  }, [gameReducer.userDetail]);

  useEffect(() => {
    if (gameReducer.balance) {
      setAmount(gameReducer.balance);
    }
  }, [gameReducer.balance]);

  return (
    <div className='agent_layout agent_home'>
      <Header logo={true} name={'Home'} agentMenu={true}/>
      <div className='agent_body'>
        <div className='slider_box2'>
          <span className='slider_box2_header'>
            Lucky <span>Minute</span> 幸运<span>1分</span>
          </span>

          <div className='user_names'>
            <span>Login ID</span>
            <span>{name}</span>
          </div>
          <div className='user_names balance'>
            <span>Balance</span>
            <span>{roundBalance(amount) || 0}</span>
          </div>
        </div>


        <div className='slider_box2'>
          <span className='Operator_Name_text' onClick={() => redirect('Message')}>Message</span>
          <div className='user_names'>
            <span className='wight_color'>0 new messages</span>
          </div>
          <div className='messages_main'>
            {[...Array(3)].map((_, i) => {
              return (
                <div className='messages' key={i}>
                  <div className='messages_inner'>
                    <span>Agent</span>
                    <span>21/2/23 22:12 </span>
                  </div>
                  <div className='messages_inner1'>
                    <span className='message_text'>
                      My new contact is 123456
                    </span>
                    <span className='bag'>90</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <FooterManager/>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ManagerDashboard);
