import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FooterManager from '../../../Components/FooterManager';
import Header from '../../../Components/Header';
import * as UserAction from '../../../Store/Actions/User';
import './Transaction.scss';
import { DATE_FORMAT } from '../../../environment.config';
import { useGameReducer } from '../../../Store';
import _ from 'lodash';

import moment from 'moment-timezone';

const ManagerTransaction = (props) => {
  const [users, setUsers] = useState([]);

  const gameReducer = useGameReducer();

  useEffect(() => {
    if (gameReducer.userDetail) {
      props.actions.userAction.getUserTransactions();
    }
  }, [gameReducer.userDetail]);
  useEffect(() => {
    let transactions = [];
    if (gameReducer.creditTransactions) {
      transactions = gameReducer.creditTransactions.map((user) => ({
        ...user,
        created: moment(user.created).format(DATE_FORMAT),
      }));
    }
    if (transactions) {
      const groupDate = _.chain(transactions)
        .groupBy('created')
        .map((value, key) => ({ created: key, transactions: value }))
        .value();

      setUsers(groupDate);
    }
  }, [gameReducer.creditTransactions]);

  return (
    <div className='agent_layout'>
      <Header name={'Manager Transaction'} />
      <div className='agent_body full_table'>
        <div className='transaction_table'>
          <div className='table_header'>
            <span className='table_header_text'>ID</span>
            <span className='table_header_text'>Account</span>
            <span className='table_header_text'>Amount</span>
            <span className='table_header_text'>Balance</span>
          </div>
          <div className='table_body_main'>
            {users.map((singlegroup, id) => (
              <div className='table_body_inner' key={id}>
                <div className="table_body_section">
                  <span className='section_title'>{singlegroup.created}</span>
                </div>
                <div className='table_body'>
                  {singlegroup.transactions.map((singleuser, id) => (
                    <div className='table_body_list' key={id}>
                      <span
                        className='body_list'
                        style={{
                          display: 'block',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {singleuser.id}
                      </span>
                      <span
                        className='body_list'
                        style={{
                          display: 'block',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {singleuser.accountId}
                      </span>
                      <span className='body_list'>
                        {+singleuser.creditAmount || -singleuser.debitAmount}
                      </span>
                      <span className='body_list'>
                        {singleuser.closingBalance}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <FooterManager />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ManagerTransaction);
