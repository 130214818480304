import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import NumberBetFrame from './NumberBetFrame';
import * as GameActions from '../../../Store/Actions/game.actions';
import RouletteWheel from '../RouletteWheel';
import './BetFrames.scss';
import ChipsOnField from '../../ChipsOnField';
import { GameType } from '../../../Utils';
import { useGameReducer } from '../../../Store';

const BettingFrame = (props) => {
  const [row, setRow] = useState([]);
  const [winner, setWinner] = useState(null);

  const gameReducer = useGameReducer();

  useEffect(() => {
    if (gameReducer.rouletteResult !== null) {
      setWinner(gameReducer.rouletteResult);
    }
  }, [gameReducer.rouletteResult, gameReducer.gameResultValue]);

  useEffect(() => {
    if (gameReducer.roulette) {
      setRow(gameReducer.roulette);
    }
  }, [gameReducer.roulette]);

  const handleClickRow = (selectedBet) => {
    props.actions.game.betSelection(GameType.Roulette, selectedBet, gameReducer.selectedChip);
  };

  return (
    <>
      <div className='main_layout game_layout'>
        <div className='main_layout_inner roulette_inner'>
          <div className='game_bet_one'>
            {[1, 2, 3].map((rowData, i) => (
              <div key={i} className='game_bet_main'>
                {row.map((data, index) => (
                  <React.Fragment key={index}>
                    {data.index === rowData && (
                      <NumberBetFrame
                        key={index}
                        value={data}
                        handleClickRow={handleClickRow}
                        winner={winner}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
          <div className='game_bet_two'>
            {row.map(
              (data, index) =>
                data.index === 4 && (
                  <span
                    key={index}
                    className={`bet_two_box ${
                      data.className ? data.className : ''
                    } ${data.isActive ? 'selected_bet' : ''}`}
                    onClick={() => handleClickRow(data)}
                  >
                    {data.value}

                    <ChipsOnField large={data.value !== '0'} data={data}></ChipsOnField>
                  </span>
                )
            )}
          </div>
          <div className='game_bet_three'>
            {row.map(
              (data, index) =>
                data.index === 5 && (
                  <span
                    key={index}
                    className={`bet_three_box ${
                      data.isActive ? 'selected_bet' : ''
                    }`}
                    onClick={() => handleClickRow(data)}
                  >
                    {data.image ? (
                      <img src={data.image} alt='' />
                    ) : (
                      data.value
                    )}

                    <ChipsOnField large={true} data={data}></ChipsOnField>
                  </span>
                )
            )}
          </div>
        </div>
      </div>
      {gameReducer.gameResultValue && (
        <div className='roulettewheel_div'>
          <RouletteWheel result={winner} />
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    game: bindActionCreators(GameActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(BettingFrame);
