import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as UserAction from '../../../Store/Actions/User';
import FooterMenu from '../../../Components/FooterMenu';
import Header from '../../../Components/Header';
import './Setting.scss';
import { useGameReducer } from '../../../Store';

const Setting = (props) => {
  const navigate = useNavigate();
  const redirect = (url) => {
    navigate(url);
  };

  const gameReducer = useGameReducer();

  useEffect(() => {
    if (gameReducer.token == null) {
      navigate('/Login');
    }
  }, [gameReducer.token]);


  const logout = () => {
    props.actions.userAction.logout();
  };

  return (
    <div className='agent_layout'>
      <Header name={'Setting'} />
      <div className='agent_body'>
        <div className='game_details_box'>
          <div className='game_details_box_inner center'>
            <div className='details_box_btn border_1 w_70' onClick={() => redirect('Language')}>
              <span className='btn_text wheat '>Language</span>
            </div>
          </div>
          <div className='game_details_box_inner center'>
            <div className='details_box_btn border_1 w_70' onClick={() => redirect('AgentChangePassword')}>
              <span className='btn_text wheat '>Change Password</span>
            </div>
          </div>
          <div className='game_details_box_inner center'>
            <div className='details_box_btn border_1 w_70' onClick={() => logout()}>
              <span className='btn_text wheat '>Logout</span>
            </div>
          </div>
        </div>
      </div>
      <FooterMenu />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(Setting);
