import React from 'react';
import { useParams } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';

export const PlayerReportSearch = () => {
  const { userName } = useParams();

  return (
    <div className='agent_body'>
      <div className='header_box'>
        <span className='row_details_text'>{userName}</span>
      </div>
      <ValidatorForm
        className='input_form_box'
        autoComplete='off'
      >
        <div className='input_form_box_inner'>
          <div className='input_form'>
            <span className='input_box_text'>Enter Start Date</span>
            <TextValidator
              validators={['required']}
              errorMessages={['this field is required']}
              variant='outlined'
              size='small'
              type='date'
              className='input_box'
            />
          </div>
          <div className='input_form'>
            <span className='input_box_text'>Enter End Date</span>
            <TextValidator
              validators={['required']}
              errorMessages={['this field is required']}
              variant='outlined'
              size='small'
              type='date'
              className='input_box'
            />
          </div>

          <div className='input_form'>
            <span className='input_box_text black'>Number of Days = 7</span>
          </div>

          <div className='input_form'>
            <span className='input_box_text'>Optional (Enter Label)</span>
            <TextValidator
              variant='outlined'
              size='small'
              type='text'
              className='input_box'
            />
          </div>
        </div>
        <div className='form_btn'>
          <Button type='submit' variant='contained' className='submit_btn'>
            Submit
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};
