import React, { bindActionCreators } from 'redux';
import { To, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect } from 'react-redux';

import './Header.scss';
import * as UserAction from '../../Store/Actions/User';
import { APP_BASE_PATH } from '../../Constants';

interface props {
  actions?: any;
  logo?: boolean;
  backToHome?: boolean;
  backToUrl?: string;
  name?: string;
  agentMenu?: boolean;
  menu?: boolean;
}

const Header = (props: props) => {
  const navigate = useNavigate();

  const redirect = (url) => {
    navigate(url);
  };

  const logout = () => {
    props.actions.userAction.logout();
  };

  return (
    <div className='header'>
      {props.logo && (
        <div className='header-button'>
          <img className='back_btn' src='/img/Logo.svg' alt='Logo' />
        </div>
      )}
      {!props.logo && (
        <div className='header-button' onClick={() => navigate((props.backToHome ? APP_BASE_PATH : (props.backToUrl || -1) as To))}>
          <i className='fa-solid fa-angle-left back_btn'></i>
        </div>
      )}
      <span className='user_name'>{props.name}</span>
      {props.agentMenu && (
        <Dropdown className='main_menu'>
          <Dropdown.Toggle id='dropdown-basic'>
            <i className='fa-solid fa-ellipsis-vertical game_info'></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => redirect('/')}>
              Message</Dropdown.Item>
            <Dropdown.Item onClick={() => redirect('DisplayName')}>
              Report</Dropdown.Item>
            <Dropdown.Item onClick={() => redirect('TransactionRecord')}>
              Agent Information</Dropdown.Item>
            <Dropdown.Item onClick={() => redirect('Languages')}>
              Language</Dropdown.Item>
            <Dropdown.Item onClick={() => redirect('ResetPassword')}>
              Change Password</Dropdown.Item>
            <Dropdown.Item onClick={() => logout()}>
              Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
      {props.menu && (
        <Dropdown className='main_menu'>
          <Dropdown.Toggle id='dropdown-basic'>
            <i className='fa-solid fa-ellipsis-vertical game_info'></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => redirect(`${APP_BASE_PATH}/DisplayName`)}>
              Display Name</Dropdown.Item>
            <Dropdown.Item>
              Agent Information</Dropdown.Item>
            <Dropdown.Item onClick={() => redirect(`${APP_BASE_PATH}/TransactionRecord`)}>
              Transaction</Dropdown.Item>
            <Dropdown.Item onClick={() => redirect(`${APP_BASE_PATH}/BetHistory`)}>
              Bet History</Dropdown.Item>
            <Dropdown.Item onClick={() => redirect(`${APP_BASE_PATH}/Languages`)}>
              Language</Dropdown.Item>
            <Dropdown.Item onClick={() => redirect(`${APP_BASE_PATH}/ResetPassword`)}>
              Change Password</Dropdown.Item>
            <Dropdown.Item onClick={() => redirect(`${APP_BASE_PATH}/SoundVibrate`)}>
              Sound & Vibrate</Dropdown.Item>
            <Dropdown.Item onClick={() => logout()}>
              Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
      {!props.menu && !props.agentMenu && (
        <div className='header-button' onClick={() => redirect(`${APP_BASE_PATH}/Guide`)}>
          <i className='fa-solid fa-circle-info game_info'></i>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(Header);
