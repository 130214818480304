import React from 'react';

interface Props {
  header: string;
  value: number | string;
}

export const GameHeaderCard = (props: Props) => {
  return (
    <div className='game_details_main'>
      <div className='game_details_inner'>
        <span className='game_details_text'>{props.header}</span>
        <span className='game_details_value'>{props.value}</span>
      </div>
    </div>
  );
};
