import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FooterMenu from '../../../../Components/FooterMenu';
import Header from '../../../../Components/Header';
import * as UserAction from '../../../../Store/Actions/User';
import { UserTransactions } from '../../../../Components/Admin/Users';

const Transaction = (props) => {
  useEffect(() => {
    props.actions.userAction.getUserTransactions();
  }, []);

  return (
    <div className='agent_layout'>
      <Header name={'Player Transaction'} agentMenu={true} />
      <UserTransactions />
      <FooterMenu/>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(Transaction);
