import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import ReplayIcon from '@material-ui/icons/Replay';

import * as UserAction from '../../../Store/Actions/User';
import Loader from '../../../Components/Loader';
import PageNotSpotted from '../../../Components/PageNotSpotted';
import Notification from '../../../Components/Notification';
import './Start.scss';
import Header from '../../../Components/Header';
import FirstLoginMessage from '../../../Components/dialogs/FirstLoginMessage';
import { DATE_FORMAT, TIME_FORMAT } from '../../../environment.config';
import { useGameReducer } from '../../../Store';

const Start = (props) => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [amount, setAmount] = useState(0);
  const [messages, setMessages] = useState(0);
  const [disableFetch, setDisableFetch] = useState(false);
  const [firstMessage, setFirstMessage] = useState(false);
  let timeout;

  const gameReducer = useGameReducer();

  const redirect = (url) => {
    navigate(url);
  };

  useEffect(() => {
    props.actions.userAction.getUserTransactions();
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (gameReducer.token == null) {
      navigate('/Login');
    } else {
      props.actions.userAction.getUserDetails();
    }
  }, [gameReducer.token]);

  useEffect(() => {
    if (gameReducer.userDetail) {
      props.actions.userAction.getUserBalanceAndMessages();
      setName(gameReducer.userDetail.name);
    }
  }, [gameReducer.userDetail]);

  useEffect(() => {
    if (gameReducer.balance) {
      setAmount(gameReducer.balance);
    }
  }, [gameReducer.balance]);

  useEffect(() => {
    if (gameReducer.messages) {
      setMessages(gameReducer.messages);
    }
  }, [gameReducer.messages]);

  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const reloadBalance = () => {
    props.actions.userAction.getUserBalanceAndMessages();
    props.actions.userAction.getUserTransactions();
    setDisableFetch(true);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setDisableFetch(false);
    }, 10000);
  };

  const hasTransaction = gameReducer.creditTransactions && gameReducer.creditTransactions[0];
  const transactionDate = hasTransaction ? moment(gameReducer.creditTransactions[0].created) : null;
  const transactionAmount = hasTransaction ? (Number(gameReducer.creditTransactions[0].creditAmount) || Number(gameReducer.creditTransactions[0].debitAmount) * -1) : 0;

  return (
    <>
      <Notification />
      <Header logo={true} name={name} menu={true}></Header>
      {firstMessage && (<FirstLoginMessage close={() => setFirstMessage(false)} />) }
      <div className='start page_container'>
        <div className='start_inner page_content'>
          <Slider {...settings} className='start_slider'>
            <div className='slider_box'>
              <span className='slider_box2_header'>
                <span>
                  娱乐<span>60秒</span>
                </span>
                <span>
                  play<span>60s</span>
                </span>
              </span>
              <span className='Operator_Name_text'>Operator_Name</span>

              <div className='user_names'>
                <span>Your Agent</span>
                <span>agent_displayname</span>
              </div>
            </div>
            <div className='slider_box'>
              <span className='Operator_Name_text'>Message</span>
              <div className='user_names'>
                <span className='wight_color'>{messages} new messages</span>
              </div>
              <div className='messages'>
                <div className='messages_inner'>
                  <span>Agent</span>
                  <span>21/2/23 22:12 </span>
                </div>
                <div className='messages_inner1'>
                  <span className='message_text'>My new contact is 123456</span>
                  <span className='bag'>90</span>
                </div>
              </div>
            </div>
          </Slider>

          <div className='user_info'>
            <div className='user_balance'>
              <span className='balance_text'>Balance</span>
              <span className='balance_text'>{amount}</span>
            </div>
            <div className='user_info_inner'>
              { hasTransaction && (<>
                <div className='user_detail'>
                  <span className='detail_text'>{transactionDate.format(DATE_FORMAT)}</span>
                  <span className='detail_text'>{transactionDate.format(TIME_FORMAT)}</span>
                </div>
                <div className='user_detail'>
                  <span className='detail_text'>{transactionAmount > 0 ? 'Deposited' : 'Withdraw' }</span>
                  <span className='detail_text'>{transactionAmount > 0 ? '+' : ''}{transactionAmount}</span>
                </div>
              </>) }
            </div>
            <div className={ 'reload_icon_icon ' + (disableFetch ? 'disable' : '') } onClick={disableFetch ? undefined : reloadBalance}>
              <ReplayIcon />
            </div>
          </div>

          <div className='games_option'>
            <div
              className='select_game_card'
              onClick={() => redirect('Baccarat')}
            >
              <img src='/img/bacarrat.svg' alt='game-img' />
              <div className='game_name'>
                <span className='baccarat_game_text1'>百家乐</span>
                <span className='baccarat_game_text'>Baccarat</span>
              </div>
            </div>

            <div className='select_game_card' onClick={() => redirect('SicBo')}>
              <img src='/img/sicbo.svg' alt='game-img' />
              <div className='game_name'>
                <span className='sicbo_game_text1'>骰宝</span>
                <span className='sicbo_game_text'>Sic Bo</span>
              </div>
            </div>

            <div
              className='select_game_card'
              onClick={() => redirect('Roulette')}
            >
              <img src='/img/roulette%20wheel.svg' alt='game-img' />
              <div className='game_name'>
                <span className='roulette_game_text1'>轮盘赌</span>
                <span className='roulette_game_text'>Roulette</span>
              </div>
            </div>
          </div>
        </div>

        {gameReducer.loading && <Loader />}
      </div>
      <PageNotSpotted />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(Start);
