import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as UserAction from '../../Store/Actions/User';
import './GameGuide.scss';
import { useGameReducer } from '../../Store';

const GameGuide = (props) => {
  const [sessions, setSessions] = useState([]);
  const [headers, setHeaders] = useState([]);

  const reducer = useGameReducer();

  useEffect(() => {
    initHeaders();
    setData();

    return () => {
      setSessions([]);
    };
  }, []);

  const initHeaders = () => {
    switch (props.gameType) {
      case 'baccarat':
        setHeaders(['Bet type', 'Payout']);
        break;
      case 'roulette':
        setHeaders(['Bet type', 'Payout']);
        break;
      case 'sicbo':
        setHeaders(['Bet type', 'Payout']);
        break;
    }
  };

  const setData = () => {
    switch (props.gameType) {
      case 'baccarat':
        setSessions([
          ['Banker', '0.95+1'],
          ['Player', '1+1'],
          ['Tie', '8+1'],
          ['Banker Pair', '11+1'],
          ['Player Pair', '11+1'],
        ]);
        break;
      case 'roulette':
        setSessions([
          ['1-9, 10-18', '1+1'],
          ['Even, Odd', '1+1'],
          ['Red, Black', '1+1'],
          ['0, 1-18', '17+1'],
        ]);
        break;
      case 'sicbo':
        setSessions([
          ['Big, Small', '1+1'],
          ['On One Dice', '1+1'],
          ['On Two Dice', '2+1'],
          ['On Three Dice', '3+1'],
          ['4-17', '50+1'],
          ['5-16', '18+1'],
          ['6-15', '14+1'],
          ['7-14', '12+1'],
          ['8-13', '8+1'],
          ['9-12', '6+1'],
          ['10-11', '6+1'],
        ]);
        break;
    }
  };

  return (
    <div className='main_layout' id='past_results'>
      <div className='main_layout_inner'>

        <div className='full_table'>
          <div className='table_header'>
            { headers.map(label => (
              <span className='table_header_text' key={label}>{label}</span>
            ))}
          </div>
          <div className={ 'table_body_main ' + (reducer.loading ? 'loading' : '') }>
            { sessions?.map(session => (
              <div className='table_body' key={session[0]}>
                <div className='table_body'>
                  <div className='table_body_list'>
                    { session.map((field, i) => (
                      <span className='body_list' key={i}>{field}</span>
                    )) }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(GameGuide);

