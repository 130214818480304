import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import './Message.scss';
import Header from '../../../Components/Header';
import FooterMenu from '../../../Components/FooterMenu';
import { bindActionCreators } from 'redux';
import * as UserAction from '../../../Store/Actions/User';
import { DATETIME_FORMAT } from '../../../environment.config';
import { useGameReducer } from '../../../Store';

const Message = (props) => {
  const { userName } = useParams();
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();
  const redirect = (url) => {
    navigate(url);
  };

  const gameReducer = useGameReducer();

  useEffect(() => {
    setMessages(gameReducer.messages || [{
      senderId: 'Sender 1',
      subject: 'Dummy message',
      createdAt: '2019-08-26T14:15:22Z'
    },{
      senderId: 'Sender 2',
      subject: 'Dummy message',
      createdAt: '2019-08-25T16:15:22Z'
    },{
      senderId: 'Sender 1',
      subject: 'Dummy message',
      createdAt: '2019-08-24T11:25:22Z'
    }]);
  }, [gameReducer.messages]);

  useEffect(() => {
    props.actions.userAction.getMessages();
  }, []);

  return (
    <div className='agent_layout'>
      <Header name={'Message'} agentMenu={true} />
      <div className='agent_body messages_body'>
        <div className='header_box'>
          <span className='sub_header_text'>{userName}</span>
        </div>
        <div className='message_list'>
          {messages?.map((message, i) => {
            const date = moment(message.createdAt);
            return (
              <div className='message' onClick={() => redirect('ReadMessage')} key={'message.' + i}>
                <div className='message_meta'>
                  <span className='sender'>{ message.senderId}</span>
                  <span className='date'>{ date.format(DATETIME_FORMAT) }</span>
                </div>
                <div className='message_body'>
                  <span className='body_list'>{ message.subject }</span>
                </div>
              </div>
            );
          })}
        </div>

        <div className='form_btn'>
          <Button
            type='submit'
            variant='contained'
            className='message_btn submit_btn lm_btn'
            onClick={() => redirect('NewMessage')}
          >
            New Message
          </Button>
        </div>
      </div>
      <FooterMenu />
      
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(Message);
