import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FooterMenu from '../../../Components/FooterMenu';
import Header from '../../../Components/Header';
import * as UserAction from '../../../Store/Actions/User';
import './Transaction.scss';
import { DATE_FORMAT, TIME_FORMAT } from '../../../environment.config';
import { useGameReducer } from '../../../Store';

import _ from 'lodash';

import moment from 'moment-timezone';

const AgentTransaction = (props) => {
  const [users, setUsers] = useState([]);

  const gameReducer = useGameReducer();

  useEffect(() => {
    if (gameReducer.userDetail) {
      props.actions.userAction.getUsers();
      props.actions.userAction.getUserTransactions();
    }
  }, [gameReducer.userDetail]);

  useEffect(() => {
    let transactions = [];
    if (gameReducer.creditTransactions) {
      transactions = gameReducer.creditTransactions.map((transaction) => ({
        ...transaction,
        user: gameReducer.users.find(u => u.user.id === transaction.accountId),
        created: moment(transaction.created).format(DATE_FORMAT),
        createdTime: moment(transaction.created).format(TIME_FORMAT),
      }));
    }
    if (transactions) {
      const groupDate = _.chain(transactions)
        .groupBy('created')
        .map((value, key) => ({ created: key, transactions: value }))
        .value();

      setUsers(groupDate);
    }
  }, [gameReducer.creditTransactions]);

  return (
    <div className='agent_layout'>
      <Header name={'Transactions'} agentMenu={true} />
      <div className='agent_body full_width'>
        <div className='full_table' id='transaction_table'>
          <div className='table_header'>
            <span className='table_header_text left'>Time</span>
            <span className='table_header_text'>Account</span>
            <span className='table_header_text'>Amount</span>
          </div>
          <div className='table_body_main'>
            {users.map((singlegroup, id) => (
              <div className='table_body_inner' key={id}>
                <div className='table_body_section'>
                  <span className='section_title'>{singlegroup.created}</span>
                </div>
                <div className='table_body'>
                  {singlegroup.transactions.map((singleuser, id) => (
                    <div className='table_body_list' key={id}>
                      <span className='body_list left'>
                        {singleuser.createdTime}
                      </span>
                      <span className='body_list'>
                        {singleuser.correspondingUsername}
                      </span>
                      <span className='body_list'>
                        {singleuser.creditAmount !== '0' ? ('+' + singleuser.creditAmount) : -singleuser.debitAmount}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <FooterMenu />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(AgentTransaction);
