import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Header from '../../../../Components/Header';
import * as UserAction from '../../../../Store/Actions/User';
import FooterMenu from '../../../../Components/FooterMenu';
import { UserList } from '../../../../Components/Admin/Users';

const User = (props) => {
  useEffect(() => {
    props.actions.userAction.getUsers();
  }, []);

  return (
    <div className='agent_layout'>
      <Header name={'Players'} agentMenu={true} backToHome={true} />
      <UserList />
      <FooterMenu />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(User);
