import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Header from '../../../../Components/Header';
import * as UserAction from '../../../../Store/Actions/User';
import FooterMenu from '../../../../Components/FooterMenu';
import { UserDetail } from '../../../../Components/Admin/Users';

const UserDetailAgent = () => {
  return (
    <div className='agent_layout'>
      <Header name={'Player Profile'} agentMenu={true} backToUrl={'/Agent/User'} />
      <UserDetail />
      <FooterMenu />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(UserDetailAgent);
