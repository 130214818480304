import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, createStore } from 'redux';
// import logger from 'redux-logger';
import promise from 'redux-promise';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import initialState from './Store/initialState';
import rootReducer from './Store';
import AppRoulette from './Views/App/AppRoulette';
import AppBaccarat from './Views/App/AppBaccarat';
import BettingFrame from './Components/Roulette/BettingFrame/BetFrames';
import SicboBetting from './Components/SicBo/SicboBetting';
import PageNotFound from './Components/PageNotFound';
import Login from './Views/Login';
import BaccaratBetting from './Components/Baccarat/BaccaratBetting';
import { LoggedIn, RequireAuthAgent, RequireAuthManager, RequireAuthPlayer, } from './Routes';
import ChangePassword from './Components/ChangePassword';
import './variables.scss';

//Agent
import HomeAgent from './Views/Agent/HomeAgent';
import AgentDashboard from './Views/Agent/Home';
import AgentReport from './Views/Agent/Report';
import SuccessAgentreport from './Views/Agent/Report/SuccessReport';
import Setting from './Views/Agent/Setting';
import AgentChangePassword from './Views/Agent/ChangePassword';
import Message from './Views/Agent/Message';
import NewMessage from './Views/Agent/NewMessage';
import ReadMessage from './Views/Agent/ReadMessage';
import AgentTransaction from './Views/Agent/Transaction';
import Language from './Views/Agent/Language';

//Agent User
import HomeUser from './Views/Agent/User';
import UserDetail from './Views/Agent/User/UserDetailHome';
import Transaction from './Views/Agent/User/Transaction';
import Label from './Views/Agent/User/Label';
import BetHistory from './Views/Agent/User/BetHistory';
import PlayerDetails from './Views/Agent/User/UserDetail';
import DepositCredit from './Views/Agent/User/DepositCredit';
import WithdrawCredit from './Views/Agent/User/WithdrawCredit';
import Report from './Views/Agent/User/Report';
import SuccessUserReport from './Views/Agent/User/Report/SuccessUserReport';

//Agent Add User
import User from './Views/Agent/User/Users';
import AddUser from './Views/Agent/User/AddUser';
import AddedUser from './Views/Agent/User/AddUser/AddedUser';

//Manager
import HomeManager from './Views/Manager/HomeManager';
import ManagerDashboard from './Views/Manager/Home';
import ManagerReport from './Views/Manager/Report';
import SuccessManagerReport from './Views/Manager/Report/SuccessReport';
import ManagerSetting from './Views/Manager/Setting';
import ManagerChangepassword from './Views/Manager/ChangePassword';
import ManagerMessage from './Views/Manager/Message';
import ManagerNewMessage from './Views/Manager/NewMessage';
import ManagerReadMessage from './Views/Manager/ReadMessage';
import ManagerTransaction from './Views/Manager/Transaction';
import ManagerLanguage from './Views/Manager/Language';

//Manager User
import AgentHome from './Views/Manager/User';
import AgentDetail from './Views/Manager/User/UserDetailHome';
import AgentTransactions from './Views/Manager/User/Transaction';
import AgentLabel from './Views/Manager/User/Label';
import ManagerUserDetails from './Views/Manager/User/UserDetail';
import AgentDepositCredit from './Views/Manager/User/DepositCredit';
import AgentWithdrawCredit from './Views/Manager/User/WithdrawCredit';
import AgentMessage from './Views/Manager/User/Message';
import AgentNewMessage from './Views/Manager/User/NewMessage';
import AgentReadMessage from './Views/Manager/User/ReadMessage';
import ManagerReportSearch from './Views/Manager/User/Report';
import SuccessAgentreports from './Views/Manager/User/Report/SuccessReport';

//Manager Add user
import Agent from './Views/Manager/User/Users';
import AddAgent from './Views/Manager/User/AddUser';
import AddedAgent from './Views/Manager/User/AddUser/AddedUser';
import PlayerBetHistory from './Views/App/PlayerBetHistory';
import TransactionRecord from './Views/App/TransactionRecord';
import ResetPassword from './Views/App/ResetPassword';
import AddDisplayname from './Views/App/AddDisplayname';
import Languages from './Views/App/Language';
import SoundVibrate from './Views/App/SoundVibrate';
import Start from './Views/App/Start';
import GameGuide from './Components/GameGuide';
import HomeApp from './Views/App/HomeApp';
import { APP_BASE_PATH, TRIAL_BASE_PATH } from './Constants';
import LandingPage from './Views/Website/LandingPage';
import HomeTrial from './Views/Trial/HomeTrial';
import TrialStart from './Views/Trial/TrialStart';
import TrialBaccarat from './Views/Trial/TrialBaccarat';
import AppSicBo from './Views/App/AppSicbo';
import TrialSicbo from './Views/Trial/TrialSicbo';
import TrialRoulette from './Views/Trial/TrialRoulette';
import TrialAllGames from './Views/Trial/TrialAllGames';

const composeEnhancers = composeWithDevTools({});
const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk, promise))
  // composeEnhancers(applyMiddleware(thunk, promise, logger))
);

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path='Login' element={
            <LoggedIn>
              <Login />
            </LoggedIn>
          }/>
          <Route path='' element={<LandingPage />}/>
          <Route path={TRIAL_BASE_PATH} element={<HomeTrial />}>
            <Route index path='' element={<TrialStart />}/>
            <Route path='All' element={<TrialAllGames />} />
            <Route path='Baccarat' element={<TrialBaccarat />}>
              <Route index element={<BaccaratBetting />} />
            </Route>
            <Route path='Roulette' element={<TrialRoulette />}>
              <Route index element={<BettingFrame />} />
            </Route>
            <Route path='Sicbo' element={<TrialSicbo />}>
              <Route index element={<SicboBetting />} />
            </Route>
          </Route>

          <Route path={APP_BASE_PATH} element={
            <RequireAuthPlayer>
              <HomeApp />
            </RequireAuthPlayer>
          }>
            <Route index path='' element={<Start />}/>
            <Route path='DisplayName' element={<AddDisplayname />}/>
            <Route path='Languages' element={<Languages />}/>
            <Route path='SoundVibrate' element={<SoundVibrate />}/>
            <Route path='ChangePassword' element={<ChangePassword />}/>
            <Route path='ResetPassword' element={<ResetPassword />}/>
            <Route path='TransactionRecord' element={<TransactionRecord />}/>
            <Route path='BetHistory' element={<PlayerBetHistory />}/>

            <Route path='Roulette' element={<AppRoulette />}>
              <Route index element={<BettingFrame />} />
              <Route path='Guide' element={<GameGuide gameType={'roulette'} />} />
            </Route>

            <Route path='Baccarat' element={<AppBaccarat />}>
              <Route index element={<BaccaratBetting />} />
              <Route path='Guide' element={<GameGuide gameType={'baccarat'} />} />
            </Route>

            <Route path='SicBo' element={<AppSicBo />}>
              <Route index element={<SicboBetting />} />
              <Route path='Guide' element={<GameGuide gameType={'sicbo'} />} />
            </Route>
          </Route>


          {/* Agent */}
          <Route path='Agent' element={
            <RequireAuthAgent>
              <HomeAgent />
            </RequireAuthAgent>
          }>
            <Route index element={<AgentDashboard />} />

            <Route path='Report' element={<AgentReport />} />
            <Route path='SuccessReport' element={<SuccessAgentreport />}/>

            <Route path='Setting' element={<Setting />} />
            <Route path='Setting/Language' element={<Language />} />
            <Route path='Setting/AgentChangePassword' element={<AgentChangePassword />}/>

            <Route path='Message' element={<Message />} />
            <Route path='Message/NewMessage' element={<NewMessage />} />
            <Route path='Message/ReadMessage' element={<ReadMessage />} />

            <Route path='User' element={<HomeUser />}>
              <Route index element={<User />} />
              <Route path='Add' element={<AddUser />} />
              <Route path='Success/:userName' element={<AddedUser />} />

              <Route path='UserDetail/:userName' element={<HomeUser />}>
                <Route index element={<UserDetail />} />
                <Route path='DepositCredit' element={<DepositCredit />} />
                <Route path='WithdrawCredit' element={<WithdrawCredit />}/>
                <Route path='Transaction' element={<Transaction />} />

                <Route path='Message' element={<Message />} />
                <Route path='Message/NewMessage' element={<NewMessage />} />
                <Route path='Message/ReadMessage' element={<ReadMessage />} />

                <Route path='Report' element={<Report />} />
                <Route path='SuccessUserReport' element={<SuccessUserReport />}/>

                <Route path='Label' element={<Label />} />
                <Route path='BetHistory' element={<BetHistory />} />
                <Route path='Details' element={<PlayerDetails />} />
              </Route>
            </Route>

            <Route path='AgentTransaction' element={<AgentTransaction />}/>
          </Route>

          {/* manager */}
          <Route path='Manager' element={
            <RequireAuthManager>
              <HomeManager />
            </RequireAuthManager>
          }>
            <Route index element={<ManagerDashboard />} />

            <Route path='Report' element={<ManagerReport />} />
            <Route path='SuccessReport' element={<SuccessManagerReport />}/>

            <Route path='Setting' element={<ManagerSetting />} />
            <Route path='Setting/Language' element={<ManagerLanguage />}/>
            <Route path='Setting/AdminChangepassword' element={<ManagerChangepassword />}/>

            <Route path='Message' element={<ManagerMessage />} />
            <Route path='Message/NewMessage' element={<ManagerNewMessage />} />
            <Route path='Message/ReadMessage' element={<ManagerReadMessage />}/>

            <Route path='User' element={<AgentHome />}>
              <Route index element={<Agent />} />
              <Route path='Add' element={<AddAgent />} />
              <Route path='Success/:userName' element={<AddedAgent />} />

              <Route path='UserDetail/:userName' element={<AgentHome />}>
                <Route index element={<AgentDetail />} />
                <Route path='DepositCredit' element={<AgentDepositCredit />}/>
                <Route path='WithdrawCredit' element={<AgentWithdrawCredit />}/>
                <Route path='Transaction' element={<AgentTransactions />}/>
                <Route path='Message' element={<AgentMessage />} />
                <Route path='Message/NewMessage' element={<AgentNewMessage />}/>
                <Route path='Message/ReadMessage' element={<AgentReadMessage />}/>
                <Route path='Report' element={<ManagerReportSearch />} />
                <Route path='SuccessUserReport' element={<SuccessAgentreports />}/>
                <Route path='Label' element={<AgentLabel />} />
                <Route path='Details' element={<ManagerUserDetails />} />
              </Route>
            </Route>

            <Route path='ManagerTransaction' element={<ManagerTransaction />}/>
          </Route>

          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </Router>
    </Provider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
