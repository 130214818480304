import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { bindActionCreators } from 'redux';

import './PlayerBetHistory.scss';
import * as UserAction from '../../../Store/Actions/User';
import Header from '../../../Components/Header';
import { BACCARAT, ROULETTE, SICBO } from '../../../Constants';
import Loader from '../../../Components/Loader';
import { DATETIME_FORMAT } from '../../../environment.config';
import { useGameReducer } from '../../../Store';

const PlayerBetHistory = (props) => {
  useEffect(() => {
    props.actions.userAction.getUserBets();
  }, []);

  const reducer = useGameReducer();

  const fetchBets = () => {
    props.actions.userAction.getUserBets(reducer.betHistory[reducer.betHistory.length - 1].created);
  };

  const getFieldName = (gameType, fieldName) => {
    switch (gameType) {
      case 'Baccarat':
        return BACCARAT.find(f => f.backEndValue === fieldName)?.name;
      case 'Roulette':
        return ROULETTE.find(f => f.backEndValue === fieldName)?.name;
      case 'Sic bo':
        return SICBO.find(f => f.backEndValue === fieldName)?.name;
    }
  };

  return (
    <div className='page_container bet_history'>
      <Header name='Bet History' />
      <div className='page_content full'>
        <div className='bet_history_main list'>
          <div className='history_header list-header'>
            <div className='history_header_inner'>
              <span className='header_inner_text'>Date Time</span>
              <span className='header_inner_text'>Game</span>
            </div>
            <div className='history_header_inner'>
              <span className='header_inner_text1'>Bet Type</span>
              <span className='header_inner_text1'>Bet</span>
              <span className='header_inner_text1'>Payout</span>
            </div>
          </div>

          <div className='bet_history_body list-body'>
            {reducer.betHistory?.sort((a, b) => a.created > b.created ? -1 : 1).map(transaction => {
              const date = moment(transaction.created);
              const gameType = transaction.gameType.includes('baccarat') ? 'Baccarat'
                : transaction.gameType.includes('roulette') ? 'Roulette'
                  : transaction.gameType.includes('sicbo') ? 'Sic bo' : null;
              const payout = transaction.payout != '<nil>' ? transaction.payout : 'Unsettled';
              const fieldName = getFieldName(gameType, transaction.fieldName);
              return (
                <div className='history_body' key={transaction.createTxId}>
                  <div className='history_body_inner'>
                    <span className='body_inner_text'>{date.format(DATETIME_FORMAT)}</span>
                    <span className='body_inner_text'>{gameType}</span>
                  </div>
                  <div className='history_body_inner'>
                    <span className='body_inner_text1'>{fieldName}</span>
                    <span className='body_inner_text1'>{transaction.amount}</span>
                    <span className='body_inner_text1'>{parseFloat(payout)}</span>
                  </div>
                </div>
              );
            })}
            { reducer.loading && <Loader></Loader> }
            { reducer.betHistory.length > 0 && !reducer.loading && reducer.betHistoryHasMore && (
              <button className='more' onClick={fetchBets}>Load more</button>
            ) }
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(PlayerBetHistory);
