import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGameReducer } from '../../../Store';
import { roundBalance } from '../../../Utils';

export const UserList = () => {
  const gameReducer = useGameReducer();
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [timeOut, setTimeOut] = useState(null);
  const [search, setSearch] = useState({
    search: '',
  });
  const labels = [
    { id: 'name', label: 'Name' },
    { id: 'label', label: 'Label' },
    { id: 'balance', label: 'Balance' }
  ];

  useEffect(() => {
    if (gameReducer.users.length > 0) {
      setUsers([...gameReducer.users]);
    }
  }, [gameReducer.users]);

  const handleChange = (event) => {
    clearTimeout(timeOut);
    const field = event.target.name;
    const commonData = { ...search };
    commonData[field] = event.target.value;
    setTimeOut(
      setTimeout(() => {
        if (commonData.search !== '') {
          const filterUsers = gameReducer.users.filter((data) =>
            data.user.name
              .toLowerCase()
              .includes(commonData.search.toLowerCase())
          );
          setUsers([...filterUsers]);
        } else {
          setUsers([...gameReducer.users]);
        }
      }, 1000)
    );
    return setSearch(commonData);
  };

  const redirect = (url) => {
    navigate(url);
  };

  return (
    <div className='agent_body full_width'>
      <div className='search_box'>
        <TextField
          variant='outlined'
          size='small'
          type='text'
          name='search'
          className='search_box_inner'
          placeholder='Search'
          value={search.search}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className='full_table' id='user_table'>
        <div className='table_header'>
          {
            labels.map((label, i) => (
              <span className={'table_header_text'} key={'header.' + i}>{label.label}</span>
            ))
          }
        </div>
        <div className='table_body_main'>
          {[...users].sort((a, b) => b.user?.created - a.user?.created).map((singleUser, index) => (
            <div className='table_body_inner' key={'user.'+index}>
              <div className='table_body'>
                <div
                  className='table_body_list'
                  onClick={() => redirect(`UserDetail/${singleUser.user.name}`)}
                >
                  <span className='body_list'>{singleUser.user.name}</span>
                  <span className='body_list'>
                    {singleUser.user.labels.length > 0
                      ? singleUser.user.labels[0]
                      : ''}
                  </span>
                  <span className='body_list'>{roundBalance(singleUser.balance)}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
