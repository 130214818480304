import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';

import './TrialStart.scss';
import * as UserAction from '../../../Store/Actions/User';
import { TRIAL_BASE_PATH } from '../../../Constants';

const TrialStart = () => {
  const navigate = useNavigate();

  return (
    <div className='trial_home page_container'>
      <div className='page_content'>
        <div className="logo">
          <img className='back_btn' src='/img/Logo.svg' alt='Logo' />
        </div>

        <div className="s_placeholder">[[ Here will come the trial games ]]</div>

        <button onClick={() => navigate(TRIAL_BASE_PATH + '/Baccarat')}>Baccarat</button>
        <button onClick={() => navigate(TRIAL_BASE_PATH + '/Roulette')}>Roulette</button>
        <button onClick={() => navigate(TRIAL_BASE_PATH + '/Sicbo')}>Sicbo</button>
        <button onClick={() => navigate('/')}>Back to website</button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(TrialStart);
