import React from 'react';
import { useNavigate } from 'react-router-dom';

import './FooterMenu.scss';
import '@fontsource/inter';

const FooterMenu = () => {
  const navigate = useNavigate();

  const redirect = (url) => {
    navigate(url);
  };
  return (
    <div className='footer_menu'>
      <div className='sub_menu' onClick={() => redirect('/Agent')}>
        <img src='/img/home.svg' alt='Home' />
        <span className='sub_menu_text'>Home</span>
      </div>
      <div className='sub_menu' onClick={() => redirect('/Agent/User')}>
        <img src='/img/profile.svg' alt='Players' />
        <span className='sub_menu_text'>Players</span>
      </div>

      <div className='sub_menu' onClick={() => redirect('/Agent/User/Add')}>
        <img src='/img/add.svg' alt='Add player' />
        <span className='sub_menu_text'>Add Player</span>
      </div>

      <div className='sub_menu' onClick={() => redirect('/Agent/AgentTransaction')}>
        <img src='/img/repot.svg' alt='Transaction' />
        <span className='sub_menu_text'>Transaction</span>
      </div>
    </div>
  );
};

export default FooterMenu;
