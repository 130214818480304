import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';

import './LandingPage.scss';
import * as UserAction from '../../../Store/Actions/User';
import { APP_BASE_PATH, TRIAL_BASE_PATH } from '../../../Constants';

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <div className='landing_page page_container'>
      <div className='page_content'>
        <div className="logo">
          <img className='back_btn' src='/img/Logo.svg' alt='Logo' />
        </div>

        <div className='s_placeholder'>[[ Here will come the website ]]</div>

        <button onClick={() => navigate(APP_BASE_PATH)}>Login to game</button>
        or
        <button className='text_btn' onClick={() => navigate(TRIAL_BASE_PATH)}>Try games without registration</button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(LandingPage);
