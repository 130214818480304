import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FooterManager from '../../../../Components/FooterManager';
import Header from '../../../../Components/Header';
import * as UserAction from '../../../../Store/Actions/User';
import { UserTransactions } from '../../../../Components/Admin/Users';

const ManagerTransactions = (props) => {
  useEffect(() => {
    props.actions.userAction.getUserTransactions();
  }, []);

  return (
    <div className='agent_layout'>
      <Header name={'Agent Transaction'} />
      <UserTransactions />
      <FooterManager />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ManagerTransactions);
