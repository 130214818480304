import React from 'react';

export const Report = () => {
  return (
    <div className='agent_body'>
      <div className='header_box'>
        <span className='sub_header_text'>Hp854876960</span>
      </div>
      <div className='row_details'>
        <div className='row_details_inner px_10'>
          <span className='row_details_text'>Start Date</span>
          <span className='row_details_text'>17/01/2022 00:00</span>
        </div>
        <div className='row_details_inner px_10'>
          <span className='row_details_text'>End Date</span>
          <span className='row_details_text'>23/01/2022 23:59</span>
        </div>
        <div className='row_details_inner px_10'>
          <span className='row_details_text'>Number of Day</span>
          <span className='row_details_text'>7</span>
        </div>
      </div>
      <div className='row_details'>
        <div className='row_details_inner px_10'>
          <span className='row_details_text black'>Number of Bets</span>
          <span className='row_details_text'>123456</span>
        </div>
        <div className='row_details_inner px_10'>
          <span className='row_details_text black'>Total Bets</span>
          <span className='row_details_text'>123456</span>
        </div>
        <div className='row_details_inner px_10'>
          <span className='row_details_text black'>Total Payout</span>
          <span className='row_details_text'>123456</span>
        </div>
        <div className='row_details_inner px_10 border_black'>
          <span className='row_details_text black'>Profit</span>
          <span className='row_details_text'>123456</span>
        </div>
      </div>
      <div className='row_details'>
        <div className='row_details_inner'>
          <span className='row_details_text center w_100'>Report Generated on 24/01/2022 21:07</span>
        </div>
      </div>
    </div>
  );
};
