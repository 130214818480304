import React from 'react';

import FooterMenu from '../../../../Components/FooterMenu';
import Header from '../../../../Components/Header';
import { Report } from '../../../../Components/Admin/Users/Report';

const SuccessUserReport = () => {
  return (
    <div className='agent_layout'>
      <Header name={'Player Report'} />
      <Report />
      <FooterMenu />
    </div>
  );
};

export default SuccessUserReport;
