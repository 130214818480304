import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Header from '../../../../Components/Header';
import { INPROGRESS, SUCCESS_MESSAGE, ERROR, LOGOUT } from '../../../../Constants';
import * as UserAction from '../../../../Store/Actions/User';
import { clearAccessToken } from '../../../../Utils';
import FooterMenu from '../../../../Components/FooterMenu';
import { useGameReducer } from '../../../../Store';
import { CreditDeposit } from '../../../../Components/Admin/Users/CreditDeposit';

const AgentDepositCredit = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userName } = useParams();

  const gameReducer = useGameReducer();

  useEffect(() => {
    if (gameReducer.users.length) {
      const user = gameReducer.users.find((u) => u.user.name === userName);
      if (!user) navigate('/Agent/UserDetail/' + userName);
    }
  }, [gameReducer.users]);

  const submit = async (payload, callback) => {
    dispatch({ type: INPROGRESS });
    UserAction.creditTransfers(payload)
      .then((response) => {
        if (response.data.success) {
          props.actions.userAction.getUserBalanceAndMessages();
          props.actions.userAction.getUsers(userName);
          callback();
          dispatch({
            type: SUCCESS_MESSAGE,
            data: {
              message: `Amount ${payload.amount} transferred successfully`,
            },
          });
        } else {
          dispatch({
            type: ERROR,
            data: { error_msg: response.data.message },
          });
        }
      })
      .catch((error) => {
        if (error && error.response) {
          if (error.response.status === 403) {
            clearAccessToken();
            dispatch({
              type: LOGOUT,
            });
          } else
            dispatch({
              type: ERROR,
              data: { error_msg: error.response.data.message },
            });
        } else {
          dispatch({
            type: ERROR,
            data: { error_msg: error.message.toString() },
          });
        }
      });
  };

  return (
    <div className='agent_layout'>
      <Header name={'Deposit Credit'} agentMenu={true} />
      <CreditDeposit submit={submit} />
      <FooterMenu />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(AgentDepositCredit);
