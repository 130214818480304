import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Header from '../../../Components/Header';
import PageNotSpotted from '../../../Components/PageNotSpotted';
import * as UserAction from '../../../Store/Actions/User';
import './ResetPassword.scss';
import { useGameReducer } from '../../../Store';

const defaultForm = {
  new_password: '',
  new_password2: '',
  old_password: '',
};

const ResetPassword = (props) => {
  const reducer = useGameReducer();

  const [formData, setFormData] = useState(defaultForm);
  const [error, setError] = useState(null);

  useEffect(() => {
    setFormData(defaultForm);
  }, [reducer.success_msg]);

  const handleChange = (event) => {
    const field = event.target.name;
    setFormData({...formData, [field]: event.target.value});
  };

  const submitForm = () => {
    if (!formData.old_password || !formData.new_password) {
      setError('Password fields are required');
    } else if (formData.new_password !== formData.new_password2) {
      setError('Password not match');
    } else {
      setError(null);
      props.actions.userAction.changePassword({
        newPassword: formData.new_password,
        oldPassword: formData.old_password
      });
    }
  };

  return (
    <div>
      <Header name='Change Password' />
      <div className='reset-password'>
        <div className='reset-password_inner'>
          <div className='reset-password_info'>
            <span className='password_text'>Important Notice</span>
            <span className='info_text'>
              There is no “forget password” service.
              <br />
              So your account will not be able to recover if you lose your
              password.
              <br />
              Please set a password that you can remember.
            </span>
          </div>
        </div>

        <div className='reset-password-div'>
          <input
            id='old_pass'
            type='password'
            name='old_password'
            className='input_box'
            placeholder='Old Password'
            value={formData.old_password}
            onChange={handleChange}
          />
          <input
            id='new_pass'
            type='password'
            name='new_password'
            className='input_box'
            placeholder='New Password'
            value={formData.new_password}
            onChange={handleChange}
          />
          <input
            id='new_pass2'
            type='password'
            name='new_password2'
            className='input_box'
            placeholder='Confirm Password'
            value={formData.new_password2}
            onChange={handleChange}
          />
          { (reducer.success_msg) && (
            <div className='message success'>{error || 'Password successfully saved'}</div>
          )}
          { (error || reducer.error_msg) && (
            <div className='message error'>{error || 'Failed to save password'}</div>
          )}
          <Button variant='contained' className='login_btn' onClick={submitForm}>
            Apply
          </Button>
        </div>
      </div>
      <PageNotSpotted />
    </div>
  );
};


const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(ResetPassword);
