import { FieldState } from '../Store';

export const BACCARAT: FieldState[] = [
  {
    value: '11-1',
    chip: null,
    isActive: false,
    index: 1,
    isConfirm: false,
    isWinner: false,
    activeChip: null,
    color: 'white',
    name: 'Player Pair',
    backEndValue: 'player_pair',
    appliedChips: 0,
  },
  {
    value: '11-1',
    chip: null,
    isActive: false,
    index: 1,
    isConfirm: false,
    isWinner: false,
    activeChip: null,
    color: 'red',
    name: 'Banker Pair',
    backEndValue: 'banker_pair',
    appliedChips: 0,
  },
  {
    value: '1-1',
    chip: null,
    isActive: false,
    index: 2,
    isConfirm: false,
    isWinner: false,
    activeChip: null,
    name: 'Player',
    color: 'white',
    backEndValue: 'player',
    appliedChips: 0,
  },
  {
    value: '8-1',
    chip: null,
    isActive: false,
    index: 2,
    isConfirm: false,
    isWinner: false,
    activeChip: null,
    name: 'Tie',
    color: 'yellow',
    backEndValue: 'tie',
    appliedChips: 0,
  },
  {
    value: '0.95-1',
    chip: null,
    isActive: false,
    index: 2,
    isConfirm: false,
    isWinner: false,
    activeChip: null,
    name: 'Banker',
    color: 'red',
    backEndValue: 'banker',
    appliedChips: 0,
  },
];
