export const defaultChips = [
  {
    value: '5',
    index: 1,
    borderColor: 'chip2',
  },
  {
    value: '10',
    index: 2,
    borderColor: 'chip3',
  },
  {
    value: '50',
    index: 3,
    borderColor: 'chip4',
  },
  {
    value: '100',
    index: 4,
    borderColor: 'chip1',
  },
  {
    value: '500',
    index: 5,
    borderColor: 'chip2',
  },
  {
    value: '1000',
    index: 6,
    borderColor: 'chip3',
  },
  {
    value: '5000',
    index: 7,
    borderColor: 'chip4',
  },
  {
    value: '10000',
    index: 8,
    borderColor: 'chip1',
  },
  {
    value: '50000',
    index: 9,
    borderColor: 'chip2',
  },
  {
    value: '100000',
    index: 10,
    borderColor: 'chip3',
  },
  {
    value: '500000',
    index: 11,
    borderColor: 'chip4',
  },
];
