import React, { useEffect, useState } from 'react';
import Dice from 'react-dice-roll';

import { getDicePosition } from '../../../Constants';
import './SicboDice.scss';
import { useGameReducer } from '../../../Store';

const SicboDice = () => {
  const [minutes, setMinutes] = useState(10);
  const gameReducer = useGameReducer();

  useEffect(() => {
    const timer = setInterval(() => setMinutes(minutes - 1), 1000);
    return () => clearInterval(timer);
  }, [minutes]);

  useEffect(() => {
    if (gameReducer.sicboResult !== null) {
      setTimeout(() => {
        (document.querySelector('#dice1 button') as HTMLButtonElement).click();
        (document.querySelector('#dice2 button') as HTMLButtonElement).click();
        (document.querySelector('#dice3 button') as HTMLButtonElement).click();
      }, 200);
    }
  }, [gameReducer.sicboResult]);

  const total = gameReducer.sicboResult
    ? gameReducer.sicboResult[0] +
      gameReducer.sicboResult[1] +
      gameReducer.sicboResult[2]
    : undefined;

  return (
    <div className='sicbodice_result_container'>
      {gameReducer.sicboResult !== null && (
        <div className='dice_main'>
          <div className='size'>
            {minutes < 2 && (
              <span>
                { gameReducer.gameResult?.multipliers.triple_any
                  ? '围'
                  : (gameReducer.gameResult?.multipliers.range_big ? '大' : '小') }
              </span>
            )}
          </div>

          <div className='dice_div'>
            <div
              id='dice1'
              className='dice1'
              style={{
                ...getDicePosition('Dice1', gameReducer.sicboResult[0]),
              }}
            >
              <Dice
                rollingTime={2500}
                size={60}
                cheatValue={gameReducer.sicboResult[0]}
              />
            </div>

            <div
              id='dice2'
              className='dice2'
              style={{
                ...getDicePosition('Dice2', gameReducer.sicboResult[1]),
              }}
            >
              <Dice
                rollingTime={4500}
                size={60}
                cheatValue={gameReducer.sicboResult[1]}
              />
            </div>

            <div
              id='dice3'
              className='dice3'
              style={{
                ...getDicePosition('Dice3', gameReducer.sicboResult[2]),
              }}
            >
              <Dice
                rollingTime={6500}
                size={60}
                cheatValue={gameReducer.sicboResult[2]}
                sound='/GameSound/shaking-dice-25620.mp3'
              />
            </div>
          </div>

          <div className='total_number'>
            {minutes < 8 && (
              <span>{`${gameReducer.sicboResult[0]}`}</span>
            )}
            {minutes < 6 && (
              <span>{`+${gameReducer.sicboResult[1]}`}</span>
            )}
            {minutes < 4 && (
              <span>{`+${gameReducer.sicboResult[2]}`}</span>
            )}
          </div>

          <div className='total_number_inner'>
            {minutes < 2 && <span>{total}</span>}
          </div>
        </div>
      )}
    </div>
  );
};

export default SicboDice;
