import React from 'react';

import './GameOverlay.scss';
import TopBets from '../dialogs/TopBets';
import BaccaratResultDisplay from '../Baccarat/BaccaratResultDisplay';
import TopPayout from '../dialogs/TopPayout';
import NetworkProblem from '../dialogs/NetworkProblem';
import ConnectionProblem from '../ConnectionProblem';
import LoadingPage from '../LoadingPage';
import { GameType } from '../../Utils';
import { StepId } from '../../Constants/gameSteps';

interface Props {
  stepId: any;
  gameType: GameType;
  minutes: number;
  winner?: any;
  sessionError: boolean;
  isOnline: boolean;
  reload: () => void;
}

export const GameOverlay = (props: Props) => {
  return (
    <>
      {props.stepId !== StepId.GAME && (<div className='no_more_bet'></div>)}
      {props.stepId === StepId.TOP_BETS && (<TopBets />)}
      {props.stepId === StepId.RESULT && props.gameType === GameType.Baccarat && props.minutes > 0 && props.minutes < 3
        && (<BaccaratResultDisplay winner={props.winner} />)}
      {props.stepId === StepId.TOP_WINNERS && (<TopPayout />)}
      {props.stepId === StepId.ERROR && props.sessionError && (<NetworkProblem callback={props.reload} />)}
      {props.stepId === StepId.OFFLINE && !props.isOnline && (<ConnectionProblem />)}
      {props.stepId === StepId.LOADING && <LoadingPage minutes={props.minutes} />}
    </>
  );
};
