import React, { useState, useEffect } from 'react';
import Card from '../PlayingCards';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as GameActions from '../../../Store/Actions/game.actions';
import './BaccaratBetting.scss';
import ChipsOnField from '../../ChipsOnField';
import { GameType } from '../../../Utils';
import { useGameReducer } from '../../../Store';

const BaccaratBetting = (props) => {
  const [minutes, setMinutes] = useState(0.5);
  const [bankerTotal, setBankerTotal] = useState(null);
  const [playerTotal, setPlayerTotal] = useState(null);
  const [playerTotalAnimation, setPlayerTotalAnimation] = useState(false);
  const [bankerTotalAnimation, setBankerTotalAnimation] = useState(false);
  const [playerFirstCard, setPlayerFirstCard] = useState(false);
  const [playerSecondCard, setPlayerSecondCard] = useState(false);
  const [playerThirdCard, setPlayerThirdCard] = useState(false);
  const [bankerFirstCard, setBankerFirstCard] = useState(false);
  const [bankerSecondCard, setBankerSecondCard] = useState(false);
  const [bankerThirdCard, setBankerThirdCard] = useState(false);

  const cardDealAudio = new Audio('/GameSound/card-deal.mp3');

  const gameReducer = useGameReducer();

  useEffect(() => {
    setPlayerTotalAnimation(true);
    const timeout = setTimeout(() => {
      setPlayerTotalAnimation(false);
    }, 150);
    return () => clearTimeout(timeout);
  }, [playerTotal]);

  useEffect(() => {
    setBankerTotalAnimation(true);
    const timeout = setTimeout(() => {
      setBankerTotalAnimation(false);
    }, 150);
    return () => clearTimeout(timeout);
  }, [bankerTotal]);

  useEffect(() => {
    if (gameReducer.gameResult === null) {
      setBankerFirstCard(false);
      setBankerSecondCard(false);
      setBankerThirdCard(false);
      setPlayerFirstCard(false);
      setPlayerSecondCard(false);
      setPlayerThirdCard(false);
      setBankerTotal(null);
      setPlayerTotal(null);
    }
  }, [gameReducer.gameResult]);

  useEffect(() => {
    const hasFlippedCard = [bankerFirstCard, bankerSecondCard, bankerThirdCard, playerFirstCard, playerSecondCard, playerThirdCard].find(a => a === true);
    if (hasFlippedCard) {
      const audio = new Audio('/GameSound/card-flip.mp3');
      audio.play();
    }
  }, [bankerFirstCard, bankerSecondCard, bankerThirdCard, playerFirstCard, playerSecondCard, playerThirdCard]);

  useEffect(() => {
    if (gameReducer.gameResultValue && gameReducer.gameResult && minutes > 0) {
      const timer = setInterval(() => setMinutes(minutes + 0.5), 500);
      if ([0.5, 2.5, 4.5, 6.5].find(v => v === minutes)) {
        cardDealAudio.play();
      }
      if ((gameReducer.gameResult.playerCards.length === 3 || gameReducer.gameResult.bankerCards.length === 3) && minutes === 9.5) {
        cardDealAudio.play();
      }
      if (gameReducer.gameResult.playerCards.length === 3 && gameReducer.gameResult.bankerCards.length === 3 && minutes === 12.5) {
        cardDealAudio.play();
      }
      if (minutes > 0.5) {
        setPlayerTotal(gameReducer.playerTotal[0].toString());
      }
      if (minutes > 2.5) {
        setBankerTotal(gameReducer.bankerTotal[0].toString());
      }
      if (minutes > 4.5) {
        setPlayerTotal(gameReducer.playerTotal[1].toString());
      }
      if (minutes > 6.5) {
        setBankerTotal(gameReducer.bankerTotal[1].toString());
      }
      if (minutes > 9.5) {
        if (gameReducer.playerTotal.length > 2) {
          setPlayerTotal(gameReducer.playerTotal[2].toString());
        } else if (
          gameReducer.playerTotal.length < 3 &&
          gameReducer.bankerTotal.length > 2
        ) {
          setBankerTotal(gameReducer.bankerTotal[2].toString());
        }
      }

      if (minutes > 12.5) {
        if (gameReducer.bankerTotal.length > 2) {
          setBankerTotal(gameReducer.bankerTotal[2].toString());
        }
      }
      return () => clearInterval(timer);
    } else {
      setMinutes(0.5);
    }
  }, [minutes, gameReducer.gameResultValue]);

  const returnCardDesign = (isActive, card) => {
    return (
      <Card rank={card.figure} suit={card.suit} />
    );
  };
  const handleClickRow = (selectedBet) => {
    props.actions.game.betSelection(GameType.Baccarat, selectedBet, gameReducer.selectedChip);
  };

  return (
    <div className='main_layout'>
      <div className='main_layout_inner'>
        <div className='baccaratbetting'>
          <div className='baccaratbetting_cards'>
            <div className='cards_inner'>
              <span className='card_text white'>
                <span>Player</span>
                <span className={'result_number ' + (playerTotalAnimation === true ? 'animation': '')}>
                  {playerTotal ? playerTotal : ''}</span>
              </span>
              {gameReducer.baccaratResult !== null && (
                <div className='all_card player'>
                  {(minutes > 9 || gameReducer.gameResultValue === null) &&
                    gameReducer.gameResult?.playerCards.length > 2 &&
                    returnCardDesign(
                      playerThirdCard,
                      gameReducer.gameResult?.playerCards[2]
                    )}

                  {(minutes > 4 || gameReducer.gameResultValue === null) && returnCardDesign(
                    playerSecondCard,
                    gameReducer.gameResult?.playerCards[1]
                  )}

                  {minutes > 0 && returnCardDesign(
                    playerFirstCard,
                    gameReducer.gameResult?.playerCards[0]
                  )}

                </div>
              )}
            </div>
            <div className='cards_inner'>
              <span className='card_text'>
                <span className={'result_number ' + (bankerTotalAnimation === true ? 'animation': '')}>
                  {bankerTotal ? bankerTotal : ''}</span>
                <span>Banker</span>
              </span>
              {gameReducer.baccaratResult !== null && (
                <div className='all_card banker'>
                  {(minutes > 2 || gameReducer.gameResultValue === null) && returnCardDesign(
                    bankerFirstCard,
                    gameReducer.gameResult?.bankerCards[0]
                  )}
                  {(minutes > 6 || gameReducer.gameResultValue === null) && returnCardDesign(
                    bankerSecondCard,
                    gameReducer.gameResult?.bankerCards[1]
                  )}

                  {((minutes > 9 &&
                    gameReducer.gameResult?.playerCards.length < 3) ||
                    minutes > 12 ||
                    gameReducer.gameResultValue === null) &&
                    gameReducer.gameResult?.bankerCards.length > 2 &&
                    returnCardDesign(
                      bankerThirdCard,
                      gameReducer.gameResult?.bankerCards[2]
                    )}
                </div>
              )}
            </div>
          </div>

          <div className='baccaratbetting_bets_row2'>
            {gameReducer.baccarat.map(
              (data, index) =>
                data.index === 1 && (
                  <div
                    key={index}
                    className={`bets2_inner ${
                      data.isActive ? 'selected-bet' : ''
                    }`}
                    onClick={() => handleClickRow(data)}
                  >
                    <span className={`bets2_text ${data.color} ${data.isActive}`}>
                      {data.name}
                    </span>
                    <span className={`banker_win_text ${data.color} ${data.isActive}`}>
                      {data.value}
                    </span>
                    <ChipsOnField large={true} data={data}></ChipsOnField>
                  </div>
                )
            )}
          </div>

          <div className='baccaratbetting_bets_row3'>
            {gameReducer.baccarat.map(
              (data, index) =>
                data.index === 2 && (
                  <div
                    key={index}
                    className={`bets_inner ${
                      data.isActive ? 'selected-bet' : ''
                    }`}
                    onClick={() => handleClickRow(data)}
                  >
                    <span className={`bets_text ${data.color} ${data.isActive}`}>
                      {data.name}
                    </span>
                    <span className={`bets_text ${data.color} ${data.isActive}`}>
                      {data.value}
                    </span>
                    <ChipsOnField large={true} data={data}></ChipsOnField>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    game: bindActionCreators(GameActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(BaccaratBetting);
