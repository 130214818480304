export const SICBO = [
  {
    name: '小',
    value: '4-10',
    chip: null,
    isActive: false,
    index: 1,
    // image: '/img/4-10.svg',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'range_small',
  },
  {
    name: 'Any triple',
    value: 'anyTriple',
    chip: null,
    isActive: false,
    index: 1,
    image: '/img/dice/DiceAll.svg',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'triple_any',
  },
  {
    name: '大',
    value: '11-17',
    chip: null,
    isActive: false,
    index: 1,
    // image: '/img/11-17.svg',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'range_big',
  },
  {
    name: 'Triple 1',
    value: 'triple1',
    chip: null,
    isActive: false,
    index: 2,
    image: '/img/dice/DiceG1.svg',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'triple_1',
  },
  {
    name: 'Triple 2',
    value: 'triple2',
    chip: null,
    isActive: false,
    index: 2,
    image: '/img/dice/DiceG2.svg',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'triple_2',
  },
  {
    name: 'Triple 3',
    value: 'triple3',
    chip: null,
    isActive: false,
    index: 2,
    image: '/img/dice/DiceG3.svg',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'triple_3',
  },
  {
    name: 'Triple 4',
    value: 'triple4',
    chip: null,
    isActive: false,
    index: 2,
    image: '/img/dice/DiceG4.svg',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'triple_4',
  },
  {
    name: 'Triple 5',
    value: 'triple5',
    chip: null,
    isActive: false,
    index: 2,
    image: '/img/dice/DiceG5.svg',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'triple_5',
  },
  {
    name: 'Triple 6',
    value: 'triple6',
    chip: null,
    isActive: false,
    index: 2,
    image: '/img/dice/DiceG6.svg',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'triple_6',
  },
  {
    name: '5',
    value: '5',
    chip: null,
    isActive: false,
    index: 3,
    color: 'white',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'sum_5',
  },
  {
    name: '7',
    value: '7',
    chip: null,
    isActive: false,
    index: 3,
    color: 'white',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'sum_7',
  },
  {
    name: '9',
    value: '9',
    chip: null,
    isActive: false,
    index: 3,
    color: 'white',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'sum_9',
  },
  {
    name: '11',
    value: '11',
    chip: null,
    isActive: false,
    index: 3,
    color: 'white',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'sum_11',
  },
  {
    name: '13',
    value: '13',
    chip: null,
    isActive: false,
    index: 3,
    color: 'white',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'sum_13',
  },
  {
    name: '15',
    value: '15',
    chip: null,
    isActive: false,
    index: 3,
    color: 'white',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'sum_15',
  },
  {
    name: '17',
    value: '17',
    chip: null,
    isActive: false,
    index: 3,
    color: 'white',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'sum_17',
  },
  {
    name: '4',
    value: '4',
    chip: null,
    isActive: false,
    index: 4,
    color: 'white',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'sum_4',
  },
  {
    name: '6',
    value: '6',
    chip: null,
    isActive: false,
    index: 4,
    color: 'white',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'sum_6',
  },
  {
    name: '8',
    value: '8',
    chip: null,
    isActive: false,
    index: 4,
    color: 'white',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'sum_8',
  },
  {
    name: '10',
    value: '10',
    chip: null,
    isActive: false,
    index: 4,
    color: 'white',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'sum_10',
  },
  {
    name: '12',
    value: '12',
    chip: null,
    isActive: false,
    index: 4,
    color: 'white',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'sum_12',
  },
  {
    name: '14',
    value: '14',
    chip: null,
    isActive: false,
    index: 4,
    color: 'white',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'sum_14',
  },
  {
    name: '16',
    value: '16',
    chip: null,
    isActive: false,
    index: 4,
    color: 'white',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'sum_16',
  },
  {
    name: 'Single 1',
    value: 'singleDice1',
    chip: null,
    isActive: false,
    index: 5,
    image: '/img/dice/Dice1.svg',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'single_1',
  },
  {
    name: 'Single 2',
    value: 'singleDice2',
    chip: null,
    isActive: false,
    index: 5,
    image: '/img/dice/Dice2.svg',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'single_2',
  },
  {
    name: 'Single 3',
    value: 'singleDice3',
    chip: null,
    isActive: false,
    index: 5,
    image: '/img/dice/Dice3.svg',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'single_3',
  },
  {
    name: 'Single 4',
    value: 'singleDice4',
    chip: null,
    isActive: false,
    index: 5,
    image: '/img/dice/Dice4.svg',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'single_4',
  },
  {
    name: 'Single 5',
    value: 'singleDice5',
    chip: null,
    isActive: false,
    index: 5,
    image: '/img/dice/Dice5.svg',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'single_5',
  },
  {
    name: 'Single 6',
    value: 'singleDice6',
    chip: null,
    isActive: false,
    index: 5,
    image: '/img/dice/Dice6.svg',
    isConfirm: false,
    activeChip: null,
    backEndValue: 'single_6',
  },
];

export const diceposition = {
  Dice1_1: {
    transform: 'rotate(5deg)',
  },
  Dice1_2: {
    transform: 'rotate(65deg)',
  },
  Dice1_3: {
    transform: 'rotate(125deg)',
  },
  Dice1_4: {
    transform: 'rotate(185deg)',
  },
  Dice1_5: {
    transform: 'rotate(245deg)',
  },
  Dice1_6: {
    transform: 'rotate(305deg)',
  },
  Dice2_1: {
    transform: 'rotate(315deg)',
  },
  Dice2_2: {
    transform: 'rotate(15deg)',
  },
  Dice2_3: {
    transform: 'rotate(75deg)',
  },
  Dice2_4: {
    transform: 'rotate(135deg)',
  },
  Dice2_5: {
    transform: 'rotate(195deg)',
  },
  Dice2_6: {
    transform: 'rotate(255deg)',
  },
  Dice3_1: {
    transform: 'rotate(285deg)',
  },
  Dice3_2: {
    transform: 'rotate(345deg)',
  },
  Dice3_3: {
    transform: 'rotate(25deg)',
  },
  Dice3_4: {
    transform: 'rotate(85deg)',
  },
  Dice3_5: {
    transform: 'rotate(165deg)',
  },
  Dice3_6: {
    transform: 'rotate(225deg)',
  },
};

export const getDicePosition = (id, value) => {
  return diceposition[`${id}_${value}`];
};
