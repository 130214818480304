import React from 'react';

import FooterManager from '../../../../Components/FooterManager';
import Header from '../../../../Components/Header';
import { Report } from '../../../../Components/Admin/Users/Report';

const ManagerSuccessReports = () => {

  return (
    <div className='agent_layout'>
      <Header name={'Agent Report'} />
      <Report />
      <FooterManager />
    </div>
  );
};


export default ManagerSuccessReports;
