import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';

import { useGameReducer } from '../../../Store';
import { ERROR } from '../../../Constants';
import { roundBalance } from '../../../Utils';

interface Payload {
  fromUserId: string,
  toUserId: string,
  amount: number,
  reference: 'Credit',
}

interface Props {
  submit: (payload: Payload, callback: () => void) => void
}

export const CreditDeposit = (props: Props) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const { userName } = useParams();
  const [data, setData] = useState({amount: 0});

  const gameReducer = useGameReducer();

  useEffect(() => {
    if (gameReducer.users.length) {
      setUser(gameReducer.users.find((u) => u.user.name === userName));
    }
  }, [gameReducer.users]);

  const submit = async () => {
    if (data.amount && data.amount !== 0) {
      props.submit({
        fromUserId: gameReducer.userDetail.id,
        toUserId: user.user.id,
        amount: data.amount,
        reference: 'Credit',
      }, () => {
        setData({amount: 0});
      });
    } else {
      dispatch({
        type: ERROR,
        data: { error_msg: 'Invalid Amount' },
      });
    }
  };

  const handleChange = (event) => {
    const field = event.target.name;
    const commonData = { ...data };
    commonData[field] = event.target.value;
    return setData(commonData);
  };

  return (
    <>
      {user && (
        <div className='agent_body'>
          <div className='row_details'>
            <div className='header_box'>
              <span className='sub_header_text'>{user.user.name}</span>
            </div>
            <div className='row_details_inner'>
              <span className='row_details_text'>Player Balance</span>
              <span className='row_details_text'>{roundBalance(user.balance)}</span>
            </div>
            <div className='row_details_inner'>
              <span className='row_details_text'>Agent Balance</span>
              <span className='row_details_text'>{roundBalance(gameReducer.balance)}</span>
            </div>
          </div>

          <ValidatorForm
            className='input_form_box'
            onSubmit={() => submit()}
            autoComplete='off'
          >
            <div className='input_form_box_inner'>
              <div className='input_form'>
                <span className='input_box_text'>Enter Deposit Amount</span>
                <TextValidator
                  validators={['required']}
                  errorMessages={['this field is required']}
                  variant='outlined'
                  size='small'
                  type='number'
                  className='input_box '
                  value={data.amount}
                  name='amount'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='form_btn'>
              <Button type='submit' variant='contained' className='submit_btn'>
                Submit
              </Button>
            </div>
          </ValidatorForm>
        </div>
      )}
    </>
  );
};
