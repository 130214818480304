import React from 'react';

import FooterManager from '../../../../Components/FooterManager';
import Header from '../../../../Components/Header';
import { PlayerReportSearch } from '../../../../Components/Admin/Users/ReportSearch';

const ManagerReportSearch = () => {
  return (
    <div className='agent_layout'>
      <Header name={'Agent Report'} />
      <PlayerReportSearch />
      <FooterManager />
    </div>
  );
};

export default ManagerReportSearch;
