/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import {
  INPROGRESS,
  IS_AUTHENTICATED,
  ERROR,
  LOGOUT,
  GET_USER,
  GET_USER_BALANCE_MESSAGES,
  GET_GAME_TIME,
  CLEAR_BETSELECTION,
  SICBO_CLEAR_BETSELECTION,
  APPLY_CHIP,
  SICBO_APPLY_CHIP,
  CONFIRMING_BET,
  SICBO_CONFIRMING_BET,
  GET_USERS,
  COMPLETE,
  NEW_USER,
  SUCCESS_MESSAGE,
  GET_USER_TRANSACTION,
  BACCARAT_APPLY_CHIP,
  BACCARAT_CONFIRMING_BET,
  BACCARAT_CLEAR_BETSELECTION,
  GET_TOP_BETS,
  ROULETTE_RESULT,
  BETS_BY_SESSION,
  SHOW_WINNING_AREA,
  BETS_BY_BACCARAT_SESSION,
  BACCARAT_RESULT,
  SHOW_BACCARAT_WINNING_AREA,
  ERROR_PAGE,
  SHOW_SICBO_WINNING_AREA,
  BETS_BY_SICBO_SESSION,
  SICBO_RESULT,
  ROULETTE_BET_SELECTION,
  SICBO_BET_SELECTION,
  BACCARAT_BET_SELECTION,
  CLEAR_GAMERESULT_VALUE,
  SHOW_ROULETTE_RESULT_AREA,
  SHOW_BACCARAT_RESULT_AREA,
  SHOW_SICBO_RESULT_AREA,
  GET_USER_BETS,
  GET_TOP_WINNERS,
  RESET_GAME,
  GET_MESSAGES,
  GET_PAST_RESULTS,
  RESET_USER_TRANSACTION,
  INIT_TRIAL_BALANCE,
  SET_STEP,
  CHANGE_TRIAL_BALANCE,
  GET_GAME_STATISTICS,
} from '../Constants';
import initialState from './initialState';
import { BACCARAT, ROULETTE, SICBO, PAGINATION_LIMIT } from '../Constants';
import { cloneDeep } from 'lodash';
import { intToString } from '../Utils';

export default function gameReducer(state = initialState.gameReducer, action) {
  let multipliers = null;
  switch (action.type) {
    case INPROGRESS:
      return {
        ...state,
        error_msg: '',
        success_msg: null,
        transactionMessage: null,
        loading: true,
        isError: false,
      };

    case COMPLETE:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
      };

    case ERROR:
      return {
        ...state,
        error_msg: action.data.error_msg + 'E:' + Math.random(),
        success_msg: null,
        transactionMessage: null,
        loading: false,
      };

    case IS_AUTHENTICATED:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        token: action.data.accessToken,
      };

    case LOGOUT:
      return {
        ...state,
        loading: false,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        token: null,
        roulette: cloneDeep(ROULETTE),
        baccarat: cloneDeep(BACCARAT),
        sicbo: cloneDeep(SICBO),
        isChipsActive: false,
        userDetail: null,
        users: [],
        newUser: null,
        balance: null,
        selectedChip: null,
        accountId: null,
        messages: null,
      };

    case GET_USER:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        userDetail: action.data.userDetail,
      };

    case GET_MESSAGES:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        loading: false,
        messages: action.data.messages,
      };

    case GET_USER_BALANCE_MESSAGES:
      const balance = Math.floor(action.data.userBalance.amount);
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        balance,
        selectedChip: balance > 10 ? (state.selectedChip || '10') : (balance > 5 ? '5' : null),
        accountId: action.data.userBalance.accountId,
        messages: null,
      };

    case INIT_TRIAL_BALANCE:
      const trialBalance = 500;
      return {
        ...state,
        balance: trialBalance,
        selectedChip: trialBalance > 10 ? (state.selectedChip || '10') : (trialBalance > 5 ? '5' : null),
      };

    case CHANGE_TRIAL_BALANCE:
      const newTrialBalance = state.balance + action.amount;
      return {
        ...state,
        balance: newTrialBalance,
        selectedChip: newTrialBalance > 10 ? (state.selectedChip || '10') : (newTrialBalance > 5 ? '5' : null),
      };


    case RESET_GAME:
      return {
        ...state,
        trial: !!action.trial,
        error_msg: null,
        gameResultValue: null,
        rouletteResult: null,
        baccaratResult: null,
        showWinningArea: false,
        gameResult: null,
        playerTotal: [],
        bankerTotal: [],
        sicboResult: null,
        roulette: cloneDeep(ROULETTE),
        baccarat: cloneDeep(BACCARAT),
        sicbo: cloneDeep(SICBO),
        stepTime: null,
        topBets: [],
        topWinners: [],
        isError: false,
        baccaratCardLength: 0,
      };

    case GET_GAME_TIME:
      return {
        ...state,
        error_msg: null,
        loading: false,
        session: cloneDeep(action.data.session),
        gameResultValue: null,
        rouletteResult: null,
        baccaratResult: null,
        showWinningArea: false,
        gameResult: null,
        playerTotal: [],
        bankerTotal: [],
        sicboResult: null,
        roulette: cloneDeep(ROULETTE),
        baccarat: cloneDeep(BACCARAT),
        sicbo: cloneDeep(SICBO),
        topBets: [],
        topWinners: [],
        isError: false,
        baccaratCardLength: 0,
      };

    case GET_PAST_RESULTS:
      return {
        ...state,
        loading: false,
        pastResults: action.data.pastResults
      };

    case GET_GAME_STATISTICS:
      return {
        ...state,
        loading: false,
        statistics: action.data
      };
    case RESET_USER_TRANSACTION:
      return {
        ...state,
        creditTransactions: [],
        creditTransactionsHasMore: false,
      };
    case GET_USER_TRANSACTION:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        creditTransactions: action.data.resetList ? action.data.creditTransactions : [...state.creditTransactions, ...action.data.creditTransactions],
        creditTransactionsHasMore: action.data.creditTransactions.length === PAGINATION_LIMIT
      };

    case GET_USER_BETS:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        betHistory: action.data.resetList ? action.data.betHistory : [...state.betHistory, ...action.data.betHistory],
        betHistoryHasMore: action.data.betHistory.length === PAGINATION_LIMIT
      };

    case GET_TOP_BETS:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        topBets: action.data.topBets || [],
      };

    case GET_TOP_WINNERS:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        topWinners: action.data.topWinners || [],
      };

      // Roulette
    case ROULETTE_BET_SELECTION:
      state.roulette.map((data) => {
        if (data.value === action.data.value) {
          data.activeChip += parseInt(state.selectedChip);
        }
      });
      return {
        ...state,
        roulette: [...state.roulette],
        confirmBet: true,
        rouletteMessage: action.data.value,
      };

    case SICBO_BET_SELECTION:
      state.sicbo.map((data) => {
        if (data.value === action.data.value) {
          data.activeChip += parseInt(state.selectedChip);
        }
      });
      return {
        ...state,
        sicbo: [...state.sicbo],
        confirmBet: true,
        rouletteMessage: action.data.value,
      };

    case BACCARAT_BET_SELECTION:
      state.baccarat.map((data) => {
        if (data.backEndValue === action.data.backEndValue) {
          data.activeChip += parseInt(state.selectedChip);
        }
      });
      return {
        ...state,
        baccarat: [...state.baccarat],
        confirmBet: true,
        rouletteMessage: action.data.value,
      };

    case SICBO_APPLY_CHIP:
      return {
        ...state,
        sicbo: [...state.sicbo],
        selectedChip: action.data,
        isSicboChipsActive: true,
        rouletteMessage: 'Please Confirm Bet',
      };

    case BACCARAT_APPLY_CHIP:
      return {
        ...state,
        baccarat: [...state.baccarat],
        selectedChip: action.data,
        isBaccaratChipsActive: true,
        rouletteMessage: 'Please Confirm Bet',
      };

    case APPLY_CHIP:
      return {
        ...state,
        roulette: [...state.roulette],
        selectedChip: action.data,
        isChipsActive: true,
        rouletteMessage: 'Please Confirm Bet',
      };

    case CLEAR_BETSELECTION:
      state.roulette.map((data) => {
        data.isActive = false;
        data.activeChip = null;
      });
      return {
        ...state,
        roulette: [...state.roulette],
        isChipsActive: false,
        confirmBet: false,
      };

    case BETS_BY_SESSION:
      let appliedChip = 0;
      state.roulette.map((roulette) => {
        roulette.isActive = false;
        roulette.chip = null;
        roulette.isWinner = null;
        action.data.map((response) => {
          if (response.fieldName === roulette.backEndValue) {
            appliedChip += parseInt(response.amount);
            roulette.chip += parseInt(response.amount);
          }
        });
      });

      return {
        ...state,
        loading: false,
        isChipsActive: false,
        confirmBet: false,
        applyChips: appliedChip,
        roulette: [...state.roulette],
        rouletteAppliedChips: appliedChip,
      };

    case BETS_BY_BACCARAT_SESSION:
      let baccaratAppliedChip = 0;
      state.baccarat.map((baccarat) => {
        baccarat.isActive = false;
        baccarat.chip = null;
        baccarat.isWinner = null;
        action.data.map((response) => {
          if (response.fieldName === baccarat.backEndValue) {
            baccaratAppliedChip += parseInt(response.amount);
            baccarat.chip += parseInt(response.amount);
          }
        });
      });
      return {
        ...state,
        loading: false,
        isChipsActive: false,
        confirmBet: false,
        applyChips: baccaratAppliedChip,
        baccarat: [...state.baccarat],
        baccaratAppliedChips: baccaratAppliedChip,
      };

    case BETS_BY_SICBO_SESSION:
      let sicboAppliedChip = 0;
      state.sicbo.map((sicbo) => {
        sicbo.isActive = false;
        sicbo.isWinner = null;
        sicbo.chip = null;
        action.data.map((response) => {
          if (response.fieldName == sicbo.backEndValue) {
            sicboAppliedChip += parseInt(response.amount);
            sicbo.chip += parseInt(response.amount);
          }
        });
      });
      return {
        ...state,
        loading: false,
        isChipsActive: false,
        confirmBet: false,
        applyChips: sicboAppliedChip,
        sicbo: [...state.sicbo],
        sicboAppliedChips: sicboAppliedChip,
      };

    case CONFIRMING_BET:
      state.roulette.map((data) => {
        data.isActive = false;
        if (data.activeChip !== null) {
          state.rouletteAppliedChips += parseInt(data.activeChip);
          data.chip += parseInt(data.activeChip);
          data.activeChip = null;
          data.isConfirm = true;
        }
      });
      //Need to Fillter result and Display
      return {
        ...state,
        loading: false,
        roulette: [...state.roulette],
        confirmBet: false,
        isChipsActive: false,
        rouletteMessage: 'Bet Accepted',
      };

    case SICBO_CONFIRMING_BET:
      state.sicbo.map((data) => {
        data.isActive = false;
        if (data.activeChip !== null) {
          state.sicboAppliedChips += parseInt(data.activeChip);
          data.chip += parseInt(data.activeChip);
          data.activeChip = null;
          data.isConfirm = true;
        }
      });
      //Need to Fillter result and Display
      return {
        ...state,
        loading: false,
        sicbo: [...state.sicbo],
        confirmBet: false,
        isSicboChipsActive: false,
        rouletteMessage: 'Bet Accepted',
        sicboAppliedChip: state.sicboAppliedChips,
      };

    case BACCARAT_CONFIRMING_BET:
      let totalChips = 0;
      state.baccarat.map((data) => {
        data.isActive = false;
        if (data.activeChip !== null) {
          data.chip += parseInt(data.activeChip);
          state.baccaratAppliedChips += parseInt(data.activeChip);
          totalChips += parseInt(data.activeChip);
          data.activeChip = null;
          data.isConfirm = true;
        }
      });
      //Need to Filter result and Display
      return {
        ...state,
        loading: false,
        balance: state.trial ? (state.balance - totalChips) : state.balance,
        baccarat: [...state.baccarat],
        confirmBet: false,
        isBaccaratChipsActive: false,
        rouletteMessage: 'Bet Accepted',
        baccaratAppliedChips: state.baccaratAppliedChips,
      };

    case SICBO_CLEAR_BETSELECTION:
      state.sicbo.map((data) => {
        data.isActive = false;
        data.activeChip = null;
      });
      return {
        ...state,
        sicbo: [...state.sicbo],
        isSicboChipsActive: false,
        confirmBet: false,
      };

    case SET_STEP:
      return {
        ...state,
        stepId: action.stepId,
        stepTime: action.stepTime,
        loading: false,
        message: action.message
      };

    case BACCARAT_CLEAR_BETSELECTION:
      state.baccarat.map((data) => {
        data.isActive = false;
        data.activeChip = null;
      });
      return {
        ...state,
        baccarat: [...state.baccarat],
        isBaccaratChipsActive: false,
        confirmBet: false,
      };

    case SHOW_WINNING_AREA:
      let result = state.gameResult?.field;
      multipliers = state.gameResult?.multipliers;
      let winAmount = 0;
      if (result === 0) {
        result = intToString(result);
      }
      state.roulette.map((roulette) => {
        if (result) {
          if (roulette.chip !== null) {
            if (multipliers[roulette.backEndValue] !== 0) {
              roulette.isConfirm = true;
              roulette.isWinner = true;
              roulette.chip =
                  roulette.chip * multipliers[roulette.backEndValue];
              winAmount += roulette.chip;
            } else {
              roulette.isWinner = false;
              roulette.chip = null;
            }
          }
        }
      });

      return {
        ...state,
        isChipsActive: false,
        confirmBet: false,
        error_msg: null,
        success_msg: null,
        winningChips: winAmount,
        showWinningArea: true,
        roulette: [...state.roulette],
      };

    case SHOW_BACCARAT_WINNING_AREA:
      const baccaratWinner = state.gameResult?.winner;
      const baccarat_Multipliers = state.gameResult?.multipliers;
      let winningAmount = 0;
      state.baccarat.map((baccarat) => {
        if (baccaratWinner) {
          if (baccarat.chip !== null) {
            if (baccarat_Multipliers[baccarat.backEndValue] !== 0) {
              baccarat.isConfirm = true;
              baccarat.isWinner = true;
              baccarat.chip =
                  baccarat.chip * baccarat_Multipliers[baccarat.backEndValue];
              winningAmount += baccarat.chip;
            } else {
              baccarat.isWinner = false;
              baccarat.chip = null;
            }
          }
        }
      });
      return {
        ...state,
        isChipsActive: false,
        confirmBet: false,
        error_msg: null,
        success_msg: null,
        winningChips: winningAmount,
        balance: state.trial ? (state.balance + winningAmount) : state.balance,
        showWinningArea: true,
        baccarat: [...state.baccarat],
      };

    case SHOW_SICBO_WINNING_AREA:
      let sicbowinningAmount = 0;
      const sicbo_Multipliers = state.gameResult?.multipliers;
      state.sicbo.map((sicbo) => {
        if (state.sicboResult) {
          if (sicbo.chip !== null) {
            if (sicbo_Multipliers[sicbo.backEndValue] !== 0) {
              sicbo.isConfirm = true;
              sicbo.isWinner = true;
              sicbo.chip = sicbo.chip * sicbo_Multipliers[sicbo.backEndValue];
              sicbowinningAmount += sicbo.chip;
            } else {
              sicbo.isWinner = false;
              sicbo.chip = null;
            }
          }
        }
      });
      return {
        ...state,
        isChipsActive: false,
        confirmBet: false,
        error_msg: null,
        success_msg: null,
        winningChips: sicbowinningAmount,
        showWinningArea: true,
        baccarat: [...state.baccarat],
      };

    case ROULETTE_RESULT:
      const rouletteResult = action.data?.results?.roulette?.field;
      const rouletteValue = action.data?.results?.roulette?.field;

      return {
        ...state,
        loading: false,
        error_msg: null,
        success_msg: null,
        rouletteResult: rouletteResult,
        gameResult: action.data?.results?.roulette,
        gameResultValue: rouletteValue.toString(),
      };

    case SHOW_ROULETTE_RESULT_AREA:
      multipliers = state.gameResult?.multipliers;
      if (state.rouletteResult === 0) {
        state.rouletteResult = intToString(state.rouletteResult);
      }
      state.roulette.map((roulette) => {
        roulette.isConfirm = false;
        if (state.rouletteResult) {
          if (multipliers[roulette.backEndValue] !== 0) {
            roulette.isActive = true;
          }
        }
      });

      return {
        ...state,
        loading: false,
        error_msg: null,
        success_msg: null,
        roulette: [...state.roulette],
      };

    case BACCARAT_RESULT:
      const baccaratResult = action.data?.results?.baccarat?.winner;
      const baccaratResultValue = action.data?.results?.baccarat?.winner;
      const playerCards = action.data?.results?.baccarat?.playerCards;
      const bankerCards = action.data?.results?.baccarat?.bankerCards;
      let cardLength = 0;
      const bankerCardTotal = [];
      const playerCardTotal = [];
      let playerTotal = 0;
      let bankerTotal = 0;

      playerCards.map((card) => {
        cardLength++;
        if (card.figure === 'A') {
          playerTotal += 1;
        } else if (
          card.figure === 'J' ||
            card.figure === 'Q' ||
            card.figure === 'K'
        ) {
          playerTotal += 0;
        } else {
          playerTotal += Number(card.figure);
        }
        playerCardTotal.push(playerTotal % 10);
      });

      bankerCards.map((card) => {
        cardLength++;
        if (card.figure === 'A') {
          bankerTotal += 1;
        } else if (
          card.figure === 'J' ||
            card.figure === 'Q' ||
            card.figure === 'K'
        ) {
          bankerTotal += 0;
        } else {
          bankerTotal += Number(card.figure);
        }
        bankerCardTotal.push(bankerTotal % 10);
      });

      if (playerTotal >= 10) {
        playerTotal = playerTotal %= 10;
      }

      if (bankerTotal >= 10) {
        bankerTotal = bankerTotal %= 10;
      }

      return {
        ...state,
        loading: false,
        error_msg: null,
        success_msg: null,
        playerTotal: playerCardTotal,
        bankerTotal: bankerCardTotal,
        baccaratResult: baccaratResult,
        gameResultValue: baccaratResultValue,
        gameResult: action.data?.results?.baccarat,
        baccaratCardLength: cardLength,
      };

    case SHOW_BACCARAT_RESULT_AREA:
      // const baccaratResult = action.data?.results?.baccarat?.winner;
      // const baccaratResultValue = action.data?.results?.baccarat?.winner;
      multipliers = state.gameResult?.multipliers || {};

      state.baccarat.map((baccarat) => {
        baccarat.isConfirm = false;
        if (state.baccaratResult) {
          if (multipliers[baccarat.backEndValue] > 1) {
            baccarat.isActive = true;
          }
        }
      });

      return {
        ...state,
        loading: false,
        error_msg: null,
        success_msg: null,
        baccarat: [...state.baccarat],
      };

    case SICBO_RESULT:
      const sicboResult = action.data?.results?.sicbo?.dice;
      const sicboResultValue = action.data?.results?.sicbo?.dice;

      return {
        ...state,
        loading: false,
        error_msg: null,
        success_msg: null,
        sicboResult: sicboResult,
        gameResult: action.data?.results?.sicbo,
        gameResultValue: sicboResultValue,
      };

    case SHOW_SICBO_RESULT_AREA:
      multipliers = state.gameResult.multipliers;

      state.sicbo.map((sicbo) => {
        sicbo.isConfirm = false;
        if (state.sicboResult) {
          if (multipliers[sicbo.backEndValue] !== 0) {
            sicbo.isActive = true;
          }
        }
      });

      return {
        ...state,
        loading: false,
        error_msg: null,
        success_msg: null,
        baccarat: [...state.baccarat],
      };

    case ERROR_PAGE:
      return {
        ...state,
        loading: false,
        isError: true,
        error_msg: state.error_msg || 'Could Not Fetch Data',
        success_msg: null,
        roulette: [...state.roulette],
        baccarat: [...state.baccarat],
        sicbo: [...state.sicbo],
        rouletteAppliedChips: 0,
        sicboAppliedChips: 0,
        baccaratAppliedChips: 0,
      };

    case GET_USERS:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        users: action.data.users,
      };

    case NEW_USER:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        newUser: action.data.user,
      };

    case SUCCESS_MESSAGE:
      return {
        ...state,
        error_msg: null,
        success_msg: action.data.message,
        loading: false,
      };

    case CLEAR_GAMERESULT_VALUE:
      return {
        ...state,
        gameResultValue: null,
      };

    default:
      return state;
  }
}
