/* eslint-disable array-callback-return */
/* eslint-disable no-unreachable */
import axios from 'axios';
import {
  ERROR,
  GET_GAME_TIME,
  GET_MESSAGES,
  GET_PAST_RESULTS,
  GET_TOP_BETS,
  GET_TOP_WINNERS,
  GET_USER,
  GET_USER_BALANCE_MESSAGES,
  GET_USER_BETS,
  GET_USER_TRANSACTION,
  GET_USERS, INIT_TRIAL_BALANCE,
  INPROGRESS,
  IS_AUTHENTICATED,
  LOGOUT,
  PAGINATION_LIMIT,
  RESET_GAME,
  RESET_USER_TRANSACTION,
  SUCCESS_MESSAGE,
} from '../../Constants';
import ENVIRONMENT_VARIABLES, { TIME_FORMAT } from '../../environment.config';
import {
  clearAccessToken,
  getAccessToken,
  getUserDetail,
  requestError,
  retryRequest,
  setAccessToken,
  setUserDetail,
} from '../../Utils';
import { toast } from 'react-toastify';
import { GameSession } from '../app.state';
import moment from 'moment-timezone';

export const login = (loginDetails) => {
  try {
    return (dispatch) => {
      dispatch({ type: INPROGRESS });
      axios
        .post(ENVIRONMENT_VARIABLES.Base_API_URL + '/user/login', loginDetails)
        .then((response) => {
          if (response.status === 200) {
            setAccessToken(response.data.accessToken);
            dispatch({
              type: IS_AUTHENTICATED,
              data: { accessToken: response.data.accessToken },
            });
          }
        })
        .catch((error) => {
          if (error && error.response) {
            dispatch({
              type: ERROR,
              data: { error_msg: error.response.data.message },
            });
          } else {
            dispatch({
              type: ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const logout = () => {
  try {
    return (dispatch) => {
      dispatch({ type: INPROGRESS });
      const token = localStorage.getItem('accessToken');
      const api = {
        method: 'POST',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/user/logout',
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            clearAccessToken();
            dispatch({
              type: LOGOUT,
            });
          }
        })
        .catch(() => {
          clearAccessToken();
          dispatch({
            type: LOGOUT,
          });
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getUserDetails = () => {
  try {
    return (dispatch) => {
      dispatch({ type: INPROGRESS });
      const token = getAccessToken();
      const api = {
        method: 'GET',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/user',
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            setUserDetail(response.data.user);
            dispatch({
              type: GET_USER,
              data: { userDetail: response.data.user },
            });
          }
        })
        .catch((error) => {
          if (error && error.response) {
            if (error.response.status === 403) {
              clearAccessToken();
              dispatch({
                type: LOGOUT,
              });
            } else
              dispatch({
                type: ERROR,
                data: { error_msg: error.response.data.message },
              });
          } else {
            dispatch({
              type: ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const setUserDetails = (username, userDetails) => {
  try {
    return (dispatch) => {
      dispatch({ type: INPROGRESS });
      const token = getAccessToken();
      const api = {
        method: 'PUT',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/user/name/' + username + '/details',
        data: userDetails
      };
      return axios(api)
        .then((response) => {
          if (response.status === 200) {
            window.location.replace(`${window.location.origin}/Agent/User/UserDetail/${username}`);
            toast.success('User detail saved');
          }
        })
        .catch(requestError(dispatch));
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getUserList = () => {
  try {
    return (dispatch) => {
      dispatch({ type: INPROGRESS });
      const token = getAccessToken();
      const api = {
        method: 'GET',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/user/list',
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_USERS,
              data: { users: response.data.users },
            });
          }
        })
        .catch((error) => {
          if (error && error.response) {
            if (error.response.status === 403) {
              clearAccessToken();
              dispatch({
                type: LOGOUT,
              });
            } else
              dispatch({
                type: ERROR,
                data: { error_msg: error.response.data.message },
              });
          } else {
            dispatch({
              type: ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getMessages = () => {
  try {
    return (dispatch) => {
      dispatch({ type: INPROGRESS });
      const token = getAccessToken();
      const api = {
        method: 'GET',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/messages',
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_MESSAGES,
              data: { messages: response.data.messages },
            });
          }
        })
        .catch((error) => {
          if (error && error.response) {
            if (error.response.status === 403) {
              clearAccessToken();
              dispatch({
                type: LOGOUT,
              });
            } else
              dispatch({
                type: ERROR,
                data: { error_msg: error.response.data.message },
              });
          } else {
            dispatch({
              type: ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getUserBalanceAndMessages = () => {
  try {
    return (dispatch) => {
      dispatch({ type: INPROGRESS });
      const token = getAccessToken();
      const api = {
        method: 'GET',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/bookmaker/credit_balance',
      };
      retryRequest(api, 'Credit balance fetching failed')
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_USER_BALANCE_MESSAGES,
              data: { userBalance: response.data },
            });
          }
        })
        .catch(requestError(dispatch));
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const initTrialBalance = () => {
  return (dispatch) => {
    dispatch({
      type: INIT_TRIAL_BALANCE
    });
  };
};

export const resetGame = () => {
  try {
    return (dispatch) => {
      dispatch({
        type: RESET_GAME
      });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const resetTrialGame = () => {
  try {
    return (dispatch) => {
      dispatch({
        type: RESET_GAME,
        trial: true
      });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getGameTime = () => {
  try {
    const requestStartTime = new Date().valueOf();
    return (dispatch) => {
      dispatch({ type: INPROGRESS });
      const token = getAccessToken();
      const api = {
        method: 'GET',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/bookmaker/games?limit=1',
      };
      return new Promise((resolve, reject) => {
        retryRequest(api, 'Game session fetching failed')
          .then((response) => {
            if (response.status === 200) {
              const requestEndTime = new Date().valueOf();
              const requestDelay = requestEndTime - requestStartTime;
              const serverTime = new Date(response.data?.serverTime).valueOf();
              const serverTimeDifference = requestEndTime - serverTime;
              const timeDifference = requestDelay / 2 - serverTimeDifference;

              const gameSession = {
                ...response.data?.sessions[0],
                startTime: moment(response.data?.sessions[0].startedAt).format(TIME_FORMAT),
                serverTime,
                timeDifference,
              } as GameSession;

              dispatch({
                type: GET_GAME_TIME,
                data: {
                  session: gameSession
                },
              });

              resolve(gameSession);
            }
          })
          .catch(requestError(dispatch, null, null, reject));

      });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getUsers = (userName = null) => {
  try {
    let apiUrl = ENVIRONMENT_VARIABLES.Base_API_URL + '/user/balance/list';
    if (userName) {
      apiUrl =
        ENVIRONMENT_VARIABLES.Base_API_URL +
        `/user/balance/list?filter.name=${userName}`;
    }

    return (dispatch) => {
      dispatch({ type: INPROGRESS });
      const token = getAccessToken();
      const api = {
        method: 'GET',
        headers: { Authorization: token },
        url: apiUrl,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.users.length > 0) {
              dispatch({
                type: GET_USERS,
                data: { users: response.data.users },
              });
            } else {
              dispatch({
                type: ERROR,
                data: { error_msg: 'we are not able to find this user' },
              });
            }
          }
        })
        .catch(requestError(dispatch));
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const creditTransfers = async (transfersDetail) => {
  const token = getAccessToken();
  const api = {
    method: 'POST',
    headers: { Authorization: token },
    url: ENVIRONMENT_VARIABLES.Base_API_URL + '/bookmaker/credit_transfers',
    data: transfersDetail,
  };
  return axios(api);
};

export const changePassword = (userDetail) => {
  try {
    return (dispatch) => {
      dispatch({ type: INPROGRESS });
      if (userDetail.oldPassword && userDetail.newPassword) {
        const token = getAccessToken();
        const user = getUserDetail();
        const api = {
          method: 'POST',
          headers: { Authorization: token },
          url:
            ENVIRONMENT_VARIABLES.Base_API_URL +
            `/user/name/${user.name}/password/replace`,
          data: userDetail,
        };
        axios(api)
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: SUCCESS_MESSAGE,
                data: {
                  message: 'Your password is successfully changed',
                },
              });
            }
          })
          .catch(requestError(dispatch));
      } else {
        dispatch({
          type: ERROR,
          data: {
            error_msg: 'OldPassword and NewPassword should not be empty',
          },
        });
      }
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const addUser = async (userDetail) => {
  const token = getAccessToken();
  const api = {
    method: 'POST',
    headers: { Authorization: token },
    url: ENVIRONMENT_VARIABLES.Base_API_URL + '/user',
    data: userDetail,
  };
  return axios(api);
};

export const resetUserTransactions = () => {
  return (dispatch) => {
    dispatch({type: INPROGRESS});
    dispatch({
      type: RESET_USER_TRANSACTION,
    });
  };
};

export const getUserTransactions = (createdBefore, limit = PAGINATION_LIMIT) => {
  try {
    return (dispatch) => {
      dispatch({ type: INPROGRESS });
      const token = getAccessToken();
      const userId = getUserDetail()?.id;
      const createdQuery = createdBefore ? `&createdBefore=${createdBefore}` : '';
      const api = {
        method: 'GET',
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL + `/bookmaker/credit_transactions?limit=${limit}&userIds=${userId}${createdQuery}&labels=transfer`,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_USER_TRANSACTION,
              data: { creditTransactions: response.data.creditTransactions, resetList: !createdBefore },
            });
          }
        })
        .catch(requestError(dispatch));
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getUserBets = (createdBefore) => {
  try {
    return (dispatch) => {
      dispatch({ type: INPROGRESS });
      const token = getAccessToken();
      const userId = getUserDetail()?.id;
      const createdQuery = createdBefore ? `&createdBefore=${createdBefore}` : '';
      const api = {
        method: 'GET',
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL + `/bookmaker/bets?limit=${PAGINATION_LIMIT}&userIds=${userId}${createdQuery}&includeProcessed=true`,
      };
      retryRequest(api, 'Bet fetching failed')
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_USER_BETS,
              data: { betHistory: response.data.bets, resetList: !createdBefore },
            });
          }
        })
        .catch((error) => {
          if (error && error.response) {
            if (error.response.status === 403) {
              clearAccessToken();
              dispatch({
                type: LOGOUT,
              });
            } else
              dispatch({
                type: ERROR,
                data: { error_msg: error.response.data.message },
              });
          } else {
            dispatch({
              type: ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getTopBets = (sessionId: string, gameType: string) => {
  try {
    return (dispatch) => {
      dispatch({ type: INPROGRESS });
      const token = getAccessToken();
      const api = {
        method: 'GET',
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL + `/bookmaker/games/${sessionId}/top_players?gameType=${gameType}`,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_TOP_BETS,
              data: { topBets: response.data?.players },
            });
          }
        })
        .catch(requestError(dispatch));
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getTopWinners = (sessionId: string) => {
  try {
    return (dispatch) => {
      dispatch({ type: INPROGRESS });
      const token = getAccessToken();
      const api = {
        method: 'GET',
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL + `/bookmaker/games/${sessionId}/winnings`,
        // ENVIRONMENT_VARIABLES.Base_API_URL + `/bookmaker/games/${sessionId}/winnings?gameType=${gameType}`,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_TOP_WINNERS,
              data: { topWinners: response.data?.results },
            });
          }
        });
      // .catch(requestError(dispatch));
    };
  } catch (error) {
    alert(error.message.toString());
  }
};


export const getPastResults = (before) => {
  try {
    return (dispatch) => {
      dispatch({ type: INPROGRESS });
      const token = getAccessToken();
      const api = {
        method: 'GET',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + `/bookmaker/games?limit=${before ? '10' : '11'}${before ? `&createdBefore=${before}` : ''}`,
      };
      retryRequest(api, 'Game session fetching failed')
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_PAST_RESULTS,
              data: {
                pastResults: response.data.sessions
              },
            });
          }
        })
        .catch(requestError(dispatch));
    };
  } catch (error) {
    alert(error.message.toString());
  }
};
