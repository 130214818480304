import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

import { useGameReducer } from '../../../Store';
import { roundBalance } from '../../../Utils';

export const UserDetail = () => {
  const gameReducer = useGameReducer();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const { userName } = useParams();

  useEffect(() => {
    if (gameReducer.users.length > 0) {
      const user = gameReducer.users.find(
        (singleUser) => singleUser.user.name === userName
      );
      if (user) setUser(user);
      else navigate('/User');
    }
  }, [gameReducer.users]);

  const redirect = (url) => {
    navigate(url);
  };

  return (
    <>
      { user && (
        <div className='agent_body'>
          <div className='row_details'>
            <div className='header_box'>
              <span className='row_details_text'>{user.user.name}</span>
            </div>

            <div className='row_details_inner'>
              <span className='row_details_text'>Balance</span>
              <span className='row_details_text'>{roundBalance(user.balance)}</span>
            </div>
          </div>

          <div className='game_details_box'>
            <div className='game_details_box_inner'>
              <div className='details_box_btn lm_btn' onClick={() => redirect('DepositCredit')}>
                <span className='btn_text wheat '>Deposit Credit</span>
              </div>
              <div className='details_box_btn lm_btn' onClick={() => redirect('WithdrawCredit')}>
                <span className='btn_text wheat '>Withdraw Credit</span>
              </div>
            </div>

            <div className='game_details_box_inner'>
              <div className='details_box_btn lm_btn' onClick={() => redirect('Transaction')}>
                <span className='btn_text wheat '>Transactions</span>
              </div>
              <div className='details_box_btn lm_btn' onClick={() => redirect('Message')}>
                <span className='btn_text wheat '>Message</span>
              </div>
            </div>

            <div className='game_details_box_inner'>
              <div className='details_box_btn lm_btn' onClick={() => redirect('Report')}>
                <span className='btn_text wheat '>Report</span>
              </div>
              <div className='details_box_btn lm_btn' onClick={() => redirect('BetHistory')}>
                <span className='btn_text wheat '>Bet History</span>
              </div>
            </div>
          </div>

          <div className='row_details'>
            <div className='row_details_inner'>
              <span className='row_details_text'>Label <EditIcon onClick={() => redirect('Label')} /></span>
              <span className='row_details_text'>
                {user.user.labels.length ? user.user.labels[1] : ''}
              </span>
            </div>

            <div className='row_details_inner'>
              <span className='row_details_text'>Detail <EditIcon onClick={() => redirect('Details')} /></span>
              <span className='row_details_text'>{user.user.details}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
