import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';

import { intToString } from '../../Utils';
import './ChipSlider.scss';
import { defaultChips } from './DefaultChips';
import { AppState } from '../../Store';

const ChipSlider = (props) => {
  const state = {
    display: true,
  };
  const [availableBalance, setAvailableBalance] = useState(0);

  const selectedChip = useSelector((state: AppState) => state.gameReducer.selectedChip);

  useEffect(() => {
    setAvailableBalance(props.balance > props.activeChips
      ? !props.trial
        ? (props.balance - props.activeChips)
        : (100-props.bets-props.activeChips)
      : 0);
  }, [props.balance, props.activeChips]);

  useEffect(() => { // check if chip is too small
    if (!!selectedChip && availableBalance < parseInt(selectedChip) && props.activeChips) {
      props.handleChip(chips.reduce((acc, cur) => {
        if (parseInt(acc) < parseInt(cur.value) && parseInt(cur.value) <= availableBalance) {
          return cur.value;
        }
        return acc;
      }, '0'));
    }
  }, [availableBalance]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const [chips] = useState([...defaultChips]);

  return (
    <Draggable bounds='parent' handle='#drag_drop'>
      <div className='draggable'>
        <i
          id='drag_drop'
          className='fa-solid fa-arrows-up-down-left-right'
        ></i>
        <div
          className='slider_main'
          style={{
            display: state.display ? 'block' : 'none',
          }}
        >
          <Slider {...settings}>
            {chips.map((ChipSlider) => (
              <div
                key={ChipSlider.index}
                className={`chip ${ChipSlider.borderColor}
                  ${ ChipSlider.value === selectedChip ? 'selected-chip' : '' } 
                  ${ parseFloat(ChipSlider.value) > availableBalance ? 'disabled' : '' } 
                  ${ intToString(ChipSlider.value).length > 3 ? 'long-text' : '' } 
                `}
                onClick={() => {
                  if (parseFloat(ChipSlider.value) <= availableBalance) {
                    props.handleChip(ChipSlider.value);
                  }
                }}
              >
                <span>{intToString(ChipSlider.value)}</span>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </Draggable>
  );
};

export default ChipSlider;
