import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Header from '../../../../Components/Header';
import * as UserAction from '../../../../Store/Actions/User';
import FooterManager from '../../../../Components/FooterManager';
import { UserDetail } from '../../../../Components/Admin/Users';

const AgentDetail = () => {
  return (
    <div className='agent_layout'>
      <Header name={'Agent Profile'} agentMenu={true} backToUrl={'/Manager/User'} />
      <UserDetail />
      <FooterManager />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(AgentDetail);
