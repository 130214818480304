import React from 'react';
import { useNavigate } from 'react-router-dom';

import '../FooterMenu/FooterMenu.scss';
import '@fontsource/inter';


const FooterManager = () => {
  const navigate = useNavigate();

  const redirect = (url) => {
    navigate(url);
  };
  return (
    <div className='footer_menu'>
      <div className='sub_menu' onClick={() => redirect('/Manager')}>
        <img src='/img/home.svg' alt='Home' />
        <span className='sub_menu_text'>Home</span>
      </div>
      <div className='sub_menu' onClick={() => redirect('/Manager/User')}>
        <img src='/img/profile.svg' alt='Agents' />
        <span className='sub_menu_text'>Agents</span>
      </div>

      <div className='sub_menu' onClick={() => redirect('/Manager/User/Add')}>
        <img src='/img/add.svg' alt='Add Agent' />
        <span className='sub_menu_text'>Add Agent</span>
      </div>

      <div className='sub_menu' onClick={() => redirect('/Manager/ManagerTransaction')}>
        <img src='/img/repot.svg' alt='Transaction' />
        <span className='sub_menu_text'>Transaction</span>
      </div>
    </div>
  );
};

export default FooterManager;
