import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '@fontsource/inter';
import '@fortawesome/fontawesome-free/css/all.min.css';

import * as GameActions from '../../../Store/Actions/game.actions';
import * as UserActions from '../../../Store/Actions/User';
import PageNotSpotted from '../../../Components/PageNotSpotted';
import './TrialAll.scss';
import { GameEngine } from '../../../Components/GameEngine';

const TrialAllGames = (props) => {
  return (
    <>
      <div className="frame">
        <GameEngine
          title='百家乐 Baccarat'
          containerClass='homebaccarat'
          actions={props.actions}
          gameType={null}
          trial={true}
        />
      </div>
      <PageNotSpotted />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    game: bindActionCreators(GameActions, dispatch),
    user: bindActionCreators(UserActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(TrialAllGames);
