import axios from 'axios';
import moment from 'moment-timezone';
import { gameSteps, StepId, GameStep } from '../../Constants/gameSteps';
import {
  APPLY_CHIP,
  BACCARAT_APPLY_CHIP,
  BACCARAT_BET_SELECTION,
  BACCARAT_CLEAR_BETSELECTION,
  BACCARAT_CONFIRMING_BET,
  BACCARAT_RESULT,
  BETS_BY_BACCARAT_SESSION,
  BETS_BY_SESSION,
  BETS_BY_SICBO_SESSION,
  CHANGE_TRIAL_BALANCE,
  CLEAR_BETSELECTION,
  CLEAR_GAMERESULT_VALUE,
  CONFIRMING_BET,
  ERROR,
  ERROR_PAGE,
  GET_GAME_STATISTICS,
  INPROGRESS,
  LOGOUT,
  RESET_GAME,
  ROULETTE_BET_SELECTION,
  ROULETTE_RESULT,
  SET_STEP,
  SHOW_BACCARAT_RESULT_AREA,
  SHOW_BACCARAT_WINNING_AREA,
  SHOW_ROULETTE_RESULT_AREA,
  SHOW_SICBO_RESULT_AREA,
  SHOW_SICBO_WINNING_AREA,
  SHOW_WINNING_AREA,
  SICBO_APPLY_CHIP,
  SICBO_BET_SELECTION,
  SICBO_CLEAR_BETSELECTION,
  SICBO_CONFIRMING_BET,
  SICBO_RESULT
} from '../../Constants';
import { GameReducerState, GameSession, GameStatistics } from '../app.state';
import { clearAccessToken, GameType, getAccessToken, requestError, retryRequest, vibrate } from '../../Utils';
import { getGameTime, getTopBets, getUserBalanceAndMessages } from './User';
import ENVIRONMENT_VARIABLES from '../../environment.config';

export const setStep = (step: GameStep) => {
  return (dispatch) => {
    dispatch({ type: SET_STEP, stepId: step.id, message: step.message, stepTime: step.time });
  };
};

export const nextStep = (nextStepId: StepId, gameReducer?: GameReducerState, gameType?: GameType, trial?: boolean) => {
  return (dispatch) => {
    const nextActiveStep = {...gameSteps.find((data) => data.id === nextStepId)};

    switch (nextStepId) {
      case StepId.GAME:
        const additionalTime = 15;
        resetGame()(dispatch);
        getGameTime()(dispatch).then((session: GameSession) => {
          const currentTime = moment(new Date().valueOf() + session.timeDifference);
          const closedAt = moment(session.closedAt);
          const diff = closedAt.diff(currentTime, 'seconds');
          const firstPage = {...gameSteps.find((data) => data.id === StepId.GAME)};
          const loadingPage = {...gameSteps.find((data) => data.id === StepId.LOADING)};
          if (diff > 0) {
            firstPage.time = diff;
            setStep(firstPage)(dispatch);
          } else {
            loadingPage.time = diff + additionalTime;
            setStep(loadingPage)(dispatch);
          }
        });
        break;

      case StepId.TOP_BETS:
        const audio = new Audio('/GameSound/time-up.mp3');
        audio.play();
        vibrate(250);
        setStep(nextActiveStep)(dispatch);
        clearBetSelection(gameType)(dispatch);
        if (!trial) {
          getTopBets(gameReducer.session.id, gameType)(dispatch);
        }
        break;

      case StepId.RESULT:
        getGameResult(gameReducer.session.id, gameType)(dispatch).then((res: any) => {
          vibrate(250);
          switch (gameType) {
            case GameType.Baccarat:
              const baccaratRes = res.results.baccarat;
              const baccaratCardLength = baccaratRes.bankerCards?.length + baccaratRes.playerCards?.length;
              if (baccaratCardLength === 6) {
                nextActiveStep.time = 17;
              } else if (baccaratCardLength === 5) {
                nextActiveStep.time = 14;
              } else {
                nextActiveStep.time = 9;
              }
              break;
            case GameType.SicBo:
            case GameType.Roulette:
              nextActiveStep.time = 14;
              break;
          }
          setStep(nextActiveStep)(dispatch);
        });
        break;

      case StepId.HIGHLIGHT_BETS:
        dispatch({type: CLEAR_GAMERESULT_VALUE});
        dispatch({type: getResultAreaActionType(gameType)});
        switch (gameType) {
          case GameType.Baccarat:
            nextActiveStep.message = gameReducer.baccaratResult;
            break;
          case GameType.Roulette:
            nextActiveStep.message = gameReducer.rouletteResult;
            break;
          case GameType.SicBo:
            dispatch({type: SHOW_SICBO_RESULT_AREA});
            const total = gameReducer.sicboResult[0] + gameReducer.sicboResult[1] + gameReducer.sicboResult[2];
            nextActiveStep.message = `${gameReducer.sicboResult[0]} + ${gameReducer.sicboResult[1]} + ${gameReducer.sicboResult[2]} = ${total}`;
            break;
        }

        setStep(nextActiveStep)(dispatch); // TODO baccaratResult
        break;

      case StepId.TOP_WINNERS:
        dispatch({type: getWinningAreaActionType(gameType)});
        setStep(nextActiveStep)(dispatch);
        break;

      case StepId.HIGHLIGHT_WINNER_BETS:
        nextActiveStep.message =`Bet ${gameReducer.baccaratAppliedChips}\n Payout ${gameReducer.winningChips}`;
        if (gameReducer.winningChips > 0) {
          const audio = new Audio('/GameSound/chip.mp3');
          audio.play();
        }
        setStep(nextActiveStep)(dispatch);
        break;

      case StepId.LOADING:
      case StepId.OFFLINE:
      case StepId.ERROR:
        setStep(nextActiveStep)(dispatch);
        break;

      default:
        break;
    }
  };
};

const confirmBetsSuccess = (gameType: GameType, trial: boolean, bets?: number) => {
  let confirmActionType: string;
  switch (gameType) {
    case GameType.Baccarat:
      confirmActionType = BACCARAT_CONFIRMING_BET;
      break;
    case GameType.SicBo:
      confirmActionType = SICBO_CONFIRMING_BET;
      break;
    case GameType.Roulette:
      confirmActionType = CONFIRMING_BET;
      break;
  }
  return (dispatch) => {
    dispatch({type: confirmActionType});
    loadBalance(trial, trial ? bets * -1 : null)(dispatch);
    const audio = new Audio('/GameSound/chip.mp3');
    audio.play();
  };
};

export const confirmBets = async (trial: boolean, userId: string, sessionId: string, gameType: GameType, gameState) => {
  return (dispatch) => {
    dispatch({ type: INPROGRESS });
    const bets = [];
    gameState.filter((data) => {
      if (data.activeChip !== null) {
        bets.push({
          userId,
          gameSessionId: sessionId,
          gameType: gameType,
          fieldName: data.backEndValue,
          amount: data.activeChip.toString(),
        });
      }
    });


    const token = getAccessToken();
    const api = {
      method: 'POST',
      headers: { Authorization: token },
      url: ENVIRONMENT_VARIABLES.Base_API_URL + '/bookmaker/bets',
      data: { bets },
    };
    if (!trial) {
      return new Promise((resolve, reject) => {
        axios(api)
          .then((response: any) => {
            if (response.status === 200) {
              if (response.data.failedBets.length === 0) {
                confirmBetsSuccess(gameType, false)(dispatch);
                resolve(true);
              } else {
                dispatch({
                  type: ERROR,
                  data: { error_msg: response.data.error[0] },
                });
                reject(ERROR);
              }
            }
          })
          .catch((error) => {
            if (error && error.response) {
              clearBetSelection(gameType);
              if (error.response.status === 403) {
                clearAccessToken();
                dispatch({
                  type: LOGOUT,
                });
              } else
                dispatch({
                  type: ERROR,
                  data: { error_msg: error.response.data.message },
                });
            } else {
              dispatch({
                type: ERROR,
                data: { error_msg: error.message.toString() },
              });
            }
            reject(ERROR);
          });
      });
    } else {
      return new Promise(resolve => {
        confirmBetsSuccess(gameType, true, gameState.filter(c => c.activeChip).reduce((acc, cur) => acc + cur.activeChip, 0))(dispatch);
        resolve({
          status: 200,
          data: {failedBets: []}
        });
      });
    }
  };
};

export const resetGame = () => {
  try {
    return (dispatch) => {
      dispatch({
        type: RESET_GAME
      });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const loadBalance = (trial: boolean, changeAmount?: number) => {
  return (dispatch) => {
    if (!trial) {
      getUserBalanceAndMessages()(dispatch);
    } else {
      dispatch({type: CHANGE_TRIAL_BALANCE, amount: changeAmount});
    }
  };
};

export const clearBetSelection = (gameType: GameType) => {
  try {
    return (dispatch) => {
      dispatch({ type: getClearBetActionType(gameType) });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

const getClearBetActionType = (gameType: GameType) => {
  switch (gameType) {
    case GameType.Baccarat: return BACCARAT_CLEAR_BETSELECTION;
    case GameType.Roulette: return CLEAR_BETSELECTION;
    case GameType.SicBo: return SICBO_CLEAR_BETSELECTION;
    default: return null;
  }
};


export const betSelection = (gameType: GameType, selectedBet, selectedChip: string) => {
  try {
    return (dispatch) => {
      if (selectedChip && selectedChip !== '0') {
        dispatch({
          type: getBetSelectionActionType(gameType),
          data: selectedBet,
        });
        vibrate(50);
      }
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

const getBetSelectionActionType = (gameType: GameType) => {
  switch (gameType) {
    case GameType.Baccarat: return BACCARAT_BET_SELECTION;
    case GameType.Roulette: return ROULETTE_BET_SELECTION;
    case GameType.SicBo: return SICBO_BET_SELECTION;
    default: return null;
  }
};

export const applyChip = (selectedChip, gameType: GameType) => {
  let actionType: string;
  switch (gameType) {
    case GameType.Baccarat:
      actionType = BACCARAT_APPLY_CHIP;
      break;
    case GameType.SicBo:
      actionType = SICBO_APPLY_CHIP;
      break;
    case GameType.Roulette:
      actionType = APPLY_CHIP;
      break;
  }

  return (dispatch) => {
    dispatch({
      type: actionType,
      data: selectedChip,
    });
  };
};

const getBetsSuccess = (bets: any, gameType: GameType, trial: boolean) => {
  let actionType: string;
  switch (gameType) {
    case GameType.Baccarat:
      actionType = BETS_BY_BACCARAT_SESSION;
      break;
    case GameType.SicBo:
      actionType = BETS_BY_SICBO_SESSION;
      break;
    case GameType.Roulette:
      actionType = BETS_BY_SESSION;
      break;
  }
  return (dispatch) => {
    if (!trial) {
      getUserBalanceAndMessages()(dispatch);
    }
    const audio = new Audio('/GameSound/chip.mp3');
    audio.play();
    dispatch({type: actionType, data: bets});
  };
};

export const getBetsBySession = (userId: string, sessionId: string, gameType: GameType, trial: boolean) => {
  try {
    return (dispatch) => {
      dispatch({ type: INPROGRESS });
      const token = getAccessToken();
      const api = {
        method: 'GET',
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          '/bookmaker/bets?userId=' +
          userId +
          '&gameSessionId=' +
          sessionId,
      };
      if (!trial) {
        return new Promise((resolve, reject) => {
          retryRequest(api, 'Bet fetching failed')
            .then((response) => {
              if (response.status === 200) {
                let bets = [];
                if (response.data.bets.length > 0 && sessionId === response.data.bets[0].gameSessionId) {
                  bets = response.data.bets;
                }
                getBetsSuccess(bets, gameType, trial)(dispatch);
                resolve(bets);
              }
            })
            .catch((error) => {
              if (error && error.response) {
                if (error.response.status === 403) {
                  clearAccessToken();
                  dispatch({
                    type: LOGOUT,
                  });
                } else
                  dispatch({
                    type: ERROR_PAGE,
                    // data: { error_msg: error.response.data.message },
                  });
              } else {
                dispatch({
                  type: ERROR,
                  data: {error_msg: error.message.toString()},
                });
              }
              reject(ERROR);
            });
        });
      } else {
        return new Promise(resolve => {
          getBetsSuccess([], gameType, trial)(dispatch);
          resolve([]);
        });
      }
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getGameResult = (sessionId, gameType: GameType) => {
  try {
    return (dispatch) => {
      dispatch({ type: INPROGRESS });
      const token = getAccessToken();
      const api = {
        method: 'GET',
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          '/bookmaker/games?sessionIds=' +
          sessionId,
      };
      return new Promise((resolve, reject) => {
        gameResult(api, sessionId)
          .then((resultSession) => {
            dispatch({
              type: getResultActionType(gameType),
              data: resultSession,
            });
            resolve(resultSession);
          })
          .catch((error) => {
            if (error && error.response) {
              if (error.response.status === 403) {
                clearAccessToken();
                dispatch({
                  type: LOGOUT,
                });
              } else
                dispatch({
                  type: ERROR,
                  data: { error_msg: error.response.data.message },
                });
            } else {
              dispatch({
                type: ERROR_PAGE,
              });
            }
            reject(ERROR);
          });
      });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

const gameResult = async (api, sessionId, count = 0) => {
  try {
    if (count < 5) {
      const response = await axios(api);
      const resultSession = response.data.sessions.find((result) => result.id === sessionId && result?.results?.roulette !== null);
      if (!resultSession) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        return await gameResult(api, sessionId, ++count);
      }
      return resultSession;
    } else {
      throw Error('method is called more than 3 times');
    }
  } catch (error) {
    throw error;
  }
};

const getResultActionType = (gameType: GameType) => {
  switch (gameType) {
    case GameType.Baccarat: return BACCARAT_RESULT;
    case GameType.Roulette: return ROULETTE_RESULT;
    case GameType.SicBo: return SICBO_RESULT;
    default: return null;
  }
};

const getWinningAreaActionType = (gameType: GameType) => {
  switch (gameType) {
    case GameType.Baccarat: return SHOW_BACCARAT_WINNING_AREA;
    case GameType.Roulette: return SHOW_WINNING_AREA;
    case GameType.SicBo: return SHOW_SICBO_WINNING_AREA;
    default: return null;
  }
};

const getResultAreaActionType = (gameType: GameType) => {
  switch (gameType) {
    case GameType.Baccarat: return SHOW_BACCARAT_RESULT_AREA;
    case GameType.Roulette: return SHOW_ROULETTE_RESULT_AREA;
    case GameType.SicBo: return SHOW_SICBO_RESULT_AREA;
    default: return null;
  }
};


export const getGameStatistics = (): (c) => Promise<GameStatistics> => {
  try {
    return (dispatch) => {
      dispatch({ type: INPROGRESS });
      const token = getAccessToken();
      const api = {
        method: 'GET',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/bookmaker/games/statistics'
      };
      return new Promise((resolve, reject) => {
        axios(api)
          .then((statistics) => {
            dispatch({
              type: GET_GAME_STATISTICS,
              data: statistics.data,
            });
            resolve(statistics.data);
          })
          .catch((error) => {
            requestError(dispatch, null)(error);
            reject(ERROR);
          });
      });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};
