import React from 'react';
import './NetworkProblem.scss';

const NetworkProblem = (props) => {
  const reloadFn = () => {
    if (typeof props.callback === 'function') {
      props.callback();
    }
  };

  return (
    <div className='center_dialog_box network_problem'>
      <div className='title'>Result loading failed</div>
      <div className='message'>Please press Reload to reconnect</div>
      <div className='action'>
        <button onClick={reloadFn}>Reload</button>
      </div>
    </div>
  );
};

export default NetworkProblem;
