import { useEffect, useState } from 'react';

export function useAutoScroll(listElementClass) {
  const [scroll, setScroll] = useState(true);
  const [time] = useState(25);

  useEffect(() => {
    let interval = null;
    if (scroll) {
      interval = setInterval(() => {
        document
          .getElementsByClassName(listElementClass)[0]
          .scrollBy(1, 0.5);
      }, time);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [scroll, time]);

  useEffect(() => {
    function stopScrolling() {
      setScroll(false);
    }
    function startScrolling() {
      setScroll(true);
    }
    document
      .getElementsByClassName(listElementClass)[0]
      .addEventListener('pointerdown', stopScrolling);
    document
      .getElementsByClassName(listElementClass)[0]
      .addEventListener('mousewheel', startScrolling);

    return () => {
      document
        .getElementsByClassName(listElementClass)[0]
        .addEventListener('pointerdown', stopScrolling);
      document
        .getElementsByClassName(listElementClass)[0]
        .addEventListener('mousewheel', startScrolling);
    };
  }, []);

  return [scroll, time];
}
