export const INPROGRESS = 'INPROGRESS';
export const COMPLETE = 'COMPLETE';
export const IS_AUTHENTICATED = 'IS_AUTHENTICATED';
export const ERROR = 'ERROR';
export const LOGOUT = 'LOGOUT';
export const GET_USER = 'GET_USER';
export const GET_USER_BALANCE_MESSAGES = 'GET_USER_BALANCE_MESSAGES';
export const INIT_TRIAL_BALANCE = 'INIT_TRIAL_BALANCE';
export const CHANGE_TRIAL_BALANCE = 'CHANGE_TRIAL_BALANCE';
export const RESET_GAME = 'RESET_GAME';
export const GET_GAME_TIME = 'GET_GAME_TIME';
export const GET_PAST_RESULTS = 'GET_PAST_RESULTS';
export const GET_USERS = 'GET_USERS';
export const NEW_USER = 'NEW_USER';
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
export const RESET_USER_TRANSACTION = 'RESET_USER_TRANSACTION';
export const GET_USER_TRANSACTION = 'GET_USER_TRANSACTION';
export const GET_USER_BETS = 'GET_USER_BETS';
export const GET_MESSAGES = 'GET_MESSAGES';
export const ERROR_PAGE = 'ERROR_PAGE';
export const CLEAR_GAMERESULT_VALUE = 'CLEAR_GAMERESULT_VALUE';
export const SET_STEP = 'SET_STEP';
export const GET_GAME_STATISTICS = 'GET_GAME_STATISTICS';

// Roulette
export const ROULETTE_BET_SELECTION = 'ROULETTE_BET_SELECTION';
export const CLEAR_BETSELECTION = 'CLEAR_BETSELECTION';
export const APPLY_CHIP = 'APPLY_CHIP';
export const CONFIRMING_BET = 'CONFIRMING_BET';
export const GET_TOP_BETS = 'GET_TOP_BETS';
export const GET_TOP_WINNERS = 'GET_TOP_WINNERS';
export const GAME_RESULT = 'GAME_RESULT';
export const ROULETTE_RESULT = 'ROULETTE_RESULT';
export const SHOW_ROULETTE_RESULT_AREA = 'SHOW_ROULETTE_RESULT_AREA';
export const BETS_BY_SESSION = 'BETS_BY_SESSION';
export const SHOW_WINNING_AREA = 'SHOW_WINNING_AREA';

export const SICBO_BET_SELECTION = 'SICBO_BET_SELECTION';
export const SICBO_APPLY_CHIP = 'SICBO_APPLY_CHIP';
export const SICBO_CONFIRMING_BET = 'SICBO_CONFIRMING_BET';
export const SICBO_CLEAR_BETSELECTION = 'SICBO_CLEAR_BETSELECTION';
export const BETS_BY_SICBO_SESSION = 'BETS_BY_SICBO_SESSION';
export const SICBO_RESULT = 'SICBO_RESULT';
export const SHOW_SICBO_RESULT_AREA = 'SHOW_SICBO_RESULT_AREA';
export const SHOW_SICBO_WINNING_AREA = 'SHOW_SICBO_WINNING_AREA';

export const BACCARAT_BET_SELECTION = 'BACCARAT_BET_SELECTION';
export const BACCARAT_APPLY_CHIP = 'BACCARAT_APPLY_CHIP';
export const BACCARAT_CONFIRMING_BET = 'BACCARAT_CONFIRMING_BET';
export const BACCARAT_CLEAR_BETSELECTION = 'BACCARAT_CLEAR_BETSELECTION';
export const BETS_BY_BACCARAT_SESSION = 'BETS_BY_BACCARAT_SESSION';
export const BACCARAT_RESULT = 'BACCARAT_RESULT';
export const SHOW_BACCARAT_RESULT_AREA = 'SHOW_BACCARAT_RESULT_AREA';
export const SHOW_BACCARAT_WINNING_AREA = 'SHOW_BACCARAT_WINNING_AREA';
