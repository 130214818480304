import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import './GameEngine.scss';
import { gameSteps, StepId } from '../../Constants/gameSteps';
import Header from '../Header';
import { GameHeader } from '../GameHeader';
import { GameOverlay } from '../GameOverlay';
import { GameType, useOnline, useVisibleChange } from '../../Utils';
import { GameFooter } from '../GameFooter';
import ChipSlider from '../ChipSlider/ChipSlider';
import { GameReducerState, useGameReducer } from '../../Store';
import { APP_BASE_PATH, TRIAL_BASE_PATH } from '../../Constants';

interface Props {
  title: string;
  containerClass: string;
  actions: {
    user: any;
    game: any;
  },
  gameType: GameType;
  trial?: boolean
}

export const GameEngine = (props: Props) => {
  const gameReducer = useGameReducer();
  const steps = [...gameSteps];

  const [activeChips, setActiveChips] = useState(0);
  const [minutes, setMinutes] = useState<number>(null);
  const [message, setMessage] = useState<string>(null);
  const [sessionError, setSessionError] = useState(false);
  const isOnline = useOnline();
  const isVisible = useVisibleChange();
  const [currentSessionId, setCurrentSessionId] = useState(null);
  const [trialSessions, setTrialSessions] = useState(0);

  useEffect(() => {
    if (isVisible) {
      startGame();
    }
  }, [isVisible]);

  useEffect(() => {
    if (!isOnline) {
      props.actions.game.nextStep(StepId.OFFLINE);
    } else {
      startGame();
    }
  }, [isOnline]);

  const startGame = (force?: boolean) => {
    if (isOnline && (!gameReducer.session || currentSessionId !== gameReducer.session?.id || force)) {
      props.actions.game.nextStep(StepId.GAME, gameReducer, props.gameType, props.trial);
      if (props.trial) {
        if (trialSessions === 0) {
          props.actions.user.initTrialBalance();
        }
        setTrialSessions(p => p >= 5 ? 1 : p + 1);
      }
    }
  };

  useEffect(() => {
    if (gameReducer.session !== null) {
      setCurrentSessionId(gameReducer.session.id);
      props.actions.game.getBetsBySession(gameReducer.userDetail?.id, gameReducer.session.id, props.gameType, props.trial);
    }
  }, [gameReducer.session]);

  useEffect(() => {
    setMessage(gameReducer.message);
  }, [gameReducer.message]);

  useEffect(() => {
    if (gameReducer.isError) {
      setSessionError(true);
      props.actions.game.nextStep(StepId.ERROR);
    }
  }, [gameReducer.isError]);

  useEffect(() => {
    if (gameReducer.stepTime) {
      setMinutes(gameReducer.stepTime);
    }
  }, [gameReducer.stepTime]);

  useEffect(() => {
    setActiveChips(getBets(props.gameType, gameReducer).reduce((acc, cur) => (acc + (cur.activeChip || 0)), 0));
  }, [gameReducer.baccarat, gameReducer.roulette, gameReducer.sicbo]);

  useEffect(() => {
    if (minutes !== null && !gameReducer.isError && isOnline && gameReducer.session !== null) {
      const activeStep = steps.find((data) => data.id === gameReducer.stepId);
      if (activeStep) {
        let timer: NodeJS.Timeout;
        if (minutes > 0) {
          timer = setTimeout(() => { setMinutes((minutes - 1)); }, 1000);
        } else if (minutes === 0) {
          props.actions.game.nextStep(activeStep.nextStep, gameReducer, props.gameType, props.trial);
        }
        return () => clearTimeout(timer);
      }
    }
  }, [minutes]);

  useEffect(() => {
    return () => props.actions.game.resetGame();
  }, []);

  const reload = () => {
    setSessionError(false);
    startGame();
  };

  const handleNext = () => {
    setMinutes(0);
  };

  const clearingBet = () => {
    props.actions.game.clearBetSelection(props.gameType);
  };

  const handleChip = (selectedChip) => {
    props.actions.game.applyChip(selectedChip, props.gameType);
  };

  const confirmingBet = () => {
    props.actions.game.confirmBets(
      props.trial,
      gameReducer.userDetail?.id,
      gameReducer.session.id,
      props.gameType,
      getBets(props.gameType, gameReducer),
    );
  };

  return (
    <div className={props.containerClass + ' page_container game_container'}>
      <Header name={props.title} backToUrl={!props.trial ? APP_BASE_PATH : TRIAL_BASE_PATH} />

      <div className='page_content'>
        <GameHeader
          startTime={gameReducer.session?.startTime}
          balance={gameReducer.balance}
          bet={getAppliedChips(props.gameType, gameReducer)}
        />

        {/*{ props.gameType === GameType.Baccarat && <BaccaratBetting />}*/}
        {/*{ props.gameType === GameType.Roulette && <BettingFrame />}*/}
        {/*{ props.gameType === GameType.SicBo && <SicboBetting />}*/}
        {/*{ props.gameType ===null && <div>*/}
        {/*  <BaccaratBetting />*/}
        {/*  <BettingFrame />*/}
        {/*  <SicboBetting />*/}
        {/*</div>}*/}
        <Outlet />

        <GameOverlay
          stepId={gameReducer.stepId}
          gameType={props.gameType}
          minutes={minutes}
          winner={getResults(props.gameType, gameReducer)}
          sessionError={sessionError}
          isOnline={isOnline}
          reload={reload}
        />

        <GameFooter
          message={message}
          gameType={props.gameType}
          clearingBet={clearingBet}
          confirmBet={gameReducer.confirmBet}
          confirmingBet={confirmingBet}
          minutes={minutes}
          stepId={gameReducer.stepId}
          handleNext={handleNext}
          reload={reload}
        />

        <ChipSlider
          handleChip={handleChip}
          balance={gameReducer.balance}
          activeChips={activeChips}
          bets={getAppliedChips(props.gameType, gameReducer)}
          trial={props.trial}
        />
      </div>
    </div>
  );
};

const getBets = (gameType: GameType, reducer: GameReducerState) => {
  switch (gameType) {
    case GameType.Baccarat: return reducer.baccarat;
    case GameType.Roulette: return reducer.roulette;
    case GameType.SicBo: return reducer.sicbo;
    default: return null;
  }
};

const getAppliedChips = (gameType: GameType, reducer: GameReducerState) => {
  switch (gameType) {
    case GameType.Baccarat: return reducer.baccaratAppliedChips;
    case GameType.Roulette: return reducer.rouletteAppliedChips;
    case GameType.SicBo: return reducer.sicboAppliedChips;
    default: return null;
  }
};

const getResults = (gameType: GameType, reducer: GameReducerState) => {
  switch (gameType) {
    case GameType.Baccarat: return reducer.baccaratResult;
    case GameType.Roulette: return reducer.rouletteResult;
    case GameType.SicBo: return reducer.sicboResult;
    default: return null;
  }
};
