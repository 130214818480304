import React from 'react';
import './TopWinners.scss';
import { useAutoScroll } from '../useAutoScroll';

const TopPayout = () => {
  useAutoScroll('list_auto_scroll');

  return (
    <div className='games_winners_list horizontal_list_dialog top_dialog_box'>
      <div className='list_inner list_title'>
        <div className='players_amount'>
          <span className='amount_text'>Top Payout</span>
        </div>
      </div>
      <div className='list_main list_auto_scroll'>
        {[...Array(100)].map((_, i) => {
          return (
            <div key={i} className='list_inner'>
              <div className='players_name'>
                <span className='player'>john007</span>
              </div>
              <div className='players_amount'>
                <span className='amount'>1950</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TopPayout;
