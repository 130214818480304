import React from 'react';
import './style.scss';

const LoadingPage = (props) => {
  return (
    <div className='loadingPage_counter'>
      <div className='counter_card'>
        { props.minutes >= 0 ? (
          <>
            <span>New Game Open in {props.minutes}</span>
          </>
        ) : (
          <>
            <span className='small'>Failed to open New Game. Retry in { 5- (props.minutes%5*-1) }</span>
          </>
        )}
      </div>
    </div>
  );
};

export default LoadingPage;
