import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import _ from 'lodash';

import { useGameReducer } from '../../../Store';
import { DATE_FORMAT } from '../../../environment.config';

export const UserTransactions = () => {
  const gameReducer = useGameReducer();
  const [users, setUsers] = useState([]);
  const { userName } = useParams();

  useEffect(() => {
    let transactions = [];
    if (gameReducer.creditTransactions) {
      transactions = gameReducer.creditTransactions.map((user) => ({
        ...user,
        created: moment(user.created).format(DATE_FORMAT),
      }));
    }
    if (transactions) {
      const groupDate = _.chain(transactions)
        .groupBy('created')
        .map((value, key) => ({ created: key, transactions: value }))
        .value();

      setUsers(groupDate);
    }
  }, [gameReducer.creditTransactions]);

  return (
    <div className='agent_body full_width'>
      <div className='header_box'>
        <span className='sub_header_text'>{ userName }</span>
      </div>

      <div className='full_table' id='transaction_table'>
        <div className='table_header'>
          <span className='table_header_text'>ID</span>
          <span className='table_header_text'>Account</span>
          <span className='table_header_text'>Amount</span>
          <span className='table_header_text'>Balance</span>
        </div>
        <div className='table_body_main'>
          { users.map((singlegroup, id) => (
            <div className='table_body_inner' key={ id }>
              <div className='table_body_inner' key={ id }>
                <div className='table_body_section'>
                  <span className='section_title'>{ singlegroup.created }</span>
                </div>
                <div className='table_body'>
                  { singlegroup.transactions.map((singleuser, id) => (
                    <div className='table_body_list' key={ id }>
                      <span
                        className='body_list'
                        style={ {
                          display: 'block',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        } }
                      >
                        { singleuser.id }
                      </span>
                      <span
                        className='body_list'
                        style={ {
                          display: 'block',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        } }
                      >
                        { singleuser.accountId }
                      </span>
                      <span className='body_list'>
                        { +singleuser.creditAmount || -singleuser.debitAmount }
                      </span>
                      <span className='body_list'>
                        { singleuser.closingBalance }
                      </span>
                    </div>
                  )) }
                </div>
              </div>
            </div>
          )) }
        </div>
      </div>
    </div>
  );
};
