import React from 'react';

import Header from '../../../../Components/Header';
import FooterMenu from '../../../../Components/FooterMenu';
import { PlayerReportSearch } from '../../../../Components/Admin/Users/ReportSearch';

const Report = () => {
  return (
    <div className='agent_layout'>
      <Header name={'Player Report'} agentMenu={true} />
      <PlayerReportSearch />
      <FooterMenu />
    </div>
  );
};

export default Report;
